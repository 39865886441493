@import url("https://fonts.googleapis.com/css?family=Overpass");
* {
    box-sizing: border-box;
    /* font-family: "Overpass", sans-serif; */
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 100%;
    padding: 0 8px;
    border: 1px solid #d6d8da;
    border-radius: 6px;
}
.tags-input:focus-within {
    border: 1px solid #F3FFF8;
}
.tags-input input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 10px;
    padding: 4px 0 0 0;
}
.tags-input input:focus {
    outline: transparent;
}
#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}
.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #252525;
    padding: 0 8px;
    font-size: 10px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #F3FFF8;
}
.tag .tag-title {
    margin-top: 3px;
}
.tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
    margin-left: 8px;
    /*color: #F3FFF8;*/
    border-radius: 50%;
    /*background: #252525;*/
    cursor: pointer;
}
@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}
