/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Sans+3:ital,wght@0,300;1,300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..600;1,400..700&family=Montserrat:ital,wght@0,100..700;1,100..900&display=swap'); */

body {
  background-color: #F3FFF8;
  color: #262323;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.Dadupa-Page {
  background: #FCFCFC;
  padding-top: 70px;
  padding-bottom: 50px;
  min-height: 100vh;
}

.display-flex {
  display: flex;
}

.login-header {
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.page-image img {
  width: 75%;
  margin: 0 auto;
  display: block;
}

.Dadupa-Login,
.Dadupa-Signup {
  min-height: 100vh;
  align-items: center;
  display: flex;
  padding-top: 15px;
}

.Dadupa-Login .row,
.Dadupa-Signup .row {
  align-items: center;
}

button:focus,
input:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.NoMargin-Top {
  margin-top: 0 !important;
}

.Margin-Bottom_30 {
  margin-bottom: 30px !important;
}

#load {
  position: fixed;
  top: 0;
  display: none;
  z-index: 99;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #F3FFF8;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #00CC66;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

@media(max-width: 768px) {

  .login-header {
    text-align: center;
    position: relative;
  }

  .login-header img {
    height: 40px;
  }

}

.login-link {
  display: block;
  margin-top: 35px;
  text-align: center;
  font-size: 12px;
  color: #78909C;
}

.login-link a {
  color: #00CC66;
  font-weight: 500;
  text-decoration: none;

}

.retrun-login{
  text-align: center;
  margin-top: 35px;
}
.retrun-login a{
  color: #00CC66;
  font-weight: 500;
  text-decoration: none;
}


/*
########
## LOGIN FORM
########
*/

.form-login {
  padding: 0 5px;
}

.form-title {
  font-weight: 700;
  font-size: 48px;
  color: #00CC66;
  margin-bottom: 35px;
}

.input-row {
  margin-bottom: 6px;
  position: relative;
}

.OrderAlph {
  font-size: 12px;
  height: 50px;
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  background: #F8F8F8;
  border: 2px solid #F8F8F8;
  color: #2D363A;
  border-radius: 30px;
}

.input-row input[type="number"],
.input-row input[type="datepicker"],
.input-row input[type="url"],
.input-row input[type="text"],
.input-row input[type="email"],
.input-row input[type="password"],
.input-row input[type="tel"],
.input-row select {
  width: 100%;
  height: 48px;
  border-radius: 15px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02); */
  border: 1px solid #fff;
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  background-color: #fff;
}

.input-row select:hover {
  cursor: pointer;
}

#reportrange {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  line-height: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  border: 2px solid #fff;
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  background-color: #f8f8f8;
}

#reportrange i {
  font-size: 18px;
}

#reportrange:hover {
  cursor: pointer;
}

.input-row select[multiple] {
  height: auto;
  padding: 10px 15px;
}

.input-row select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-row textarea {
  width: 100%;
  height: 120px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  border: 2px solid #fff;
  padding: 15px;
  font-size: 12px;
  transition: all .30s linear;
  background-color: #fff;
}

.input-row textarea:focus {
  border-color: #00b601 !important;
}

.input-select::before {
  display: block;
  content: '\eb3a';
  font-family: 'unicons';
  font-style: normal;
  font-weight: 400;
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 18px;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.input-select-multi::before {
  display: none;
}

.input-row input[type="url"]:focus,
.input-row input[type="tel"]:focus,
.input-row input[type="datepicker"]:focus,
.input-row input[type="number"]:focus,
.input-row input[type="tewt"]:focus,
.input-row input[type="email"]:focus,
.input-row input[type="password"]:focus,
.input-row input[type="tel"]:focus,
.input-row select:focus {
  border-color: #00CC66;
  box-shadow: none;
  outline: none;
}

.input-row .input-icon {
  position: absolute;
  top: 11px;
  right: 20px;
  font-size: 18px;
}

.toggle-password {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
  color: #BEBEBE;
}

.toggle-password:hover {
  cursor: pointer;
}

.login-options {
  display: flex;
  width: 100%;
  margin-top: 25px;
}

.login-options div {
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 12px;
  color: #78909C;
}

.login-options .forgot-password {
  text-align: right;
}

.login-options .forgot-password a {
  color: #78909C;
}

.form-submit {
  margin-top: 15px;
}

label.error {
  position: absolute;
  top: 13px;
  right: 10px;
  background-color: red;
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
  font-size: 9px;
  margin: 0;
  z-index: 9;
}

.action-button,
.form-submit button {
  background-color: #00CC66;
  width: 100%;
  height: 48px;
  border-radius: 15px;
  border: 2px solid #00CC66;
  /* text-transform: uppercase; */
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  box-shadow: none;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.action-button:hover,
.form-submit button:hover {
  background-color: transparent;
  color: #00CC66;
}

.flex-prev-btn.action-button,
.flex-next-btn.action-button {
  display: block;
  width: 48%;
  float: left;
}

.flex-prev-btn.action-button{
  background-color: #D8E0E4;
  border-color: #D8E0E4;
}

.flex-next-btn.action-button {
  float: right;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  color: #78909C;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-checkbox .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #F6F6F6;
  box-shadow: 0 0 10px rgba(0, 0, 0, .02);
  border-radius: 4px;
  transition: all .20s linear;
}

.container-checkbox:hover input~.checkmark {
  background-color: #fff;
}

.container-checkbox input:checked~.checkmark {
  background-color: #00CC66;
  border-color: #00CC66;
}

.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked~.checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 0.5px;
  top: -2px;
  font-size: 12px;
  content: '\e9c3';
  font-family: 'unicons';
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container-radio:hover input~.checkmark {
  background-color: #ccc;
}

.container-radio input:checked~.checkmark {
  background-color: #2196F3;
}

.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-radio input:checked~.checkmark:after {
  display: block;
}

.container-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.social-login {
  /* margin-bottom: 35px; */
}

.social-login-label {
  display: block;
  text-align: center;
  font-size: 12px;
  margin: 30px 0 20px;
  color: #78909C;
}

.social-login-options {
  display: flex;
  flex-wrap: wrap;
}

.social-login-options .social-option {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 5px;
}

.social-login-options .social-option a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .02);
  color: #00CC66;
  font-size: 22px;
  transition: all .30s linear
}

.social-login-options .social-option a:hover {
  background-color: #00CC66;
  color: #F3FFF8;
}

.social-login-options .social-option span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .02);
  color: #00CC66;
  font-size: 22px;
  transition: all .30s linear
}

.social-login-options .social-option span:hover {
  background-color: #00CC66;
  color: #F3FFF8;
}

.social-login-options .social-option .uim-svg {
  height: auto;
}

.social-login-options .social-option .uim-svg svg {
  position: relative;
  top: -2px;
}

/*
########
## SIGNUP FORM
########
*/

/* .form-signup {
  min-height: 420px;
  margin-top: 50px;
} */

.form-signup.move-form {
  min-height: 520px;
}

.form-signup {
  position: relative;
}

.form-signup-header{
  margin-bottom: 30px;
}
.signup-title {
  font-size: 24px;
  font-weight: 700;
  color: #474747;
  line-height: 26px;
}
.form-signup-header p{

}

.form-signup fieldset {
  width: 100%;
  /* position: absolute; */
}

.form-signup fieldset:not(:first-of-type) {
  display: none;
}

#progressbar {
  margin: 0 0 25px;
  padding: 0;
}

#progressbar li {
  list-style-type: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 1px solid #B6C3C9;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  color: #B6C3C9;
  font-size: 10px;
  margin-right: 40px;
  position: relative;
  transition: all .30s linear;
}

#progressbar li.done .uil {
  margin: 0;
  width: auto;
  height: auto;
  opacity: 1;
  transition: all .30s linear;
}

#progressbar li .uil {
  opacity: 0;
  transition: all .30s linear;
  position: absolute;
  top: 0;
  left: 1.5px;
  font-size: 16px;
}

#progressbar li span {
  display: block;
  opacity: 1;
  transition: all .30s linear;
}

#progressbar li.done span {
  opacity: 0;
  transition: all .30s linear;
}

#progressbar li:last-child {
  margin-right: 0px;
}

#progressbar li::after {
  content: "";
  display: block;
  width: 35px;
  height: 1px;
  background-color: #B6C3C9;
  position: absolute;
  right: -40px;
  top: 11px;
}

#progressbar li::before {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: #B6C3C9;
  position: absolute;
  right: -40px;
  top: 11px;
  z-index: 9;
  transition: all .50s linear;
}

#progressbar li.done::before {
  width: 35px;
  background-color: #00CC66;
}

#progressbar li:last-child::before,
#progressbar li:last-child::after {
  display: none;
}

#progressbar li.active {
  background-color: #00CC66;
  color: #F3FFF8;
  border-color: #00CC66;
}

.prev-next {
  flex-wrap: wrap;
  display: flex;
}

.input-button-prev {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 5px;
}

.input-button-next {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 5px;
}

.form-notice {
  font-size: 10px;
  line-height: 16px;
  color: #78909C;
  margin-top: 15px;
}

.form-notice a {
  color: #00CC66;
  font-weight: 700;
}

.form-inputs .form-notice {
  margin-bottom: 20px;
}

.step-field {
  position: relative;
}

.step-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.step-edit {
  position: absolute;
  top: 0;
  right: 0;
}

.step-edit button {
  background-color: transparent;
  font-size: 12px;
  color: #00CC66;
  border: none;
  padding: 0;
}

.step-field {
  margin-bottom: 15px;
}

.step-row {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  /* border-radius: 30px; */
  /* margin-bottom: 5px; */
}
.step-row:first-child{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.step-row:last-child{
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.step-row .step-value {
  flex: 0 0 60%;
  max-width: 60%;
  text-align: right;
  color: #2D363A;
}

.step-row .step-label {
  flex: 0 0 40%;
  max-width: 40%;
  font-weight: 500;
  color: #78909C;
}

@media(max-width: 768px) {

  .form-login,
  .form-signup {
    text-align: center;
    margin-top: 15px;
  }

}

/*
########
## FOOTER
########
*/

.Dadupa-Footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
}

.Dadupa-Links .Dadupa-Links-Wrap {
  margin: 0;
  padding: 0;
  text-align: right;
}

.Dadupa-Links .Dadupa-Link {
  list-style-type: none;
  display: inline-block;
  margin: 0 8px
}

.Dadupa-Copyright,
.Dadupa-Links .Dadupa-Link a {
  font-size: 10px;
  color: #78909C;
  transition: all .30s linear;
}

.Dadupa-Links .Dadupa-Link a:hover {
  color: #00CC66;
  text-decoration: none;
}

.Lang-Switcher {
  position: relative;
}

.Lang-Switcher select {
  width: auto;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  border: 2px solid #fff;
  font-size: 11px;
  padding: 0 40px 0 15px;
  transition: all .30s linear;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 35px;
}

.Lang-Switcher.input-select::before {
  right: 5px;
  top: 3px;
}

@media(max-width: 768px) {
  .Dadupa-Footer {
    position: relative;
    text-align: center;
    margin-top: 35px;
    bottom: 0;
  }

  .Dadupa-Links {
    display: none;
  }

  .Dadupa-Login,
  .Dadupa-Signup {
    height: auto;
    display: flex;
    margin-top: 35px;
    align-items: center;
  }

  .Dadupa-Login .row,
  .Dadupa-Signup .row {
    height: auto;
  }

  /* .Dadupa-Connect{
    height: 100vh;
  } */
}

@media(min-width: 768px) and (max-width: 992px) {
  .login-header {
    position: relative;
    text-align: center;
  }

  .signup-title {
    text-align: center;
  }

  #progressbar {
    text-align: center;
  }

  .form-notice {
    text-align: center;
  }

  .Dadupa-Login .row,
  .Dadupa-Signup .row {
    height: auto;
  }

  .form-wrapper {
    width: 400px;
    margin: 35px auto;
    text-align: center;
  }

  .page-image img {
    width: 100%;
  }

  .Dadupa-Signup {
    padding: 35px 0;
  }

  .Dadupa-Footer {
    text-align: center;
    height: auto;
  }

  .Dadupa-Links .Dadupa-Links-Wrap {
    text-align: center;
  }

  .Dadupa-Copyright,
  .Dadupa-Links .Dadupa-Link a {
    font-size: 10px;
  }

  .Dadupa-Login,
  .Dadupa-Signup {
    height: auto;
  }

  .Dadupa-Connect {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* height: 100vh; */
  }

  .Connection-Wrapper {}
}

/*
########
## DADUPA HEADER
########
*/

.Dadupa-Header {
  background: #fff;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  position: absolute;
  width: 100%;
  top: 0;
}

.Dadupa-Header-Fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
}

.left-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
}

.center-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
}

.right-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
}

.Dadupa-Nav {
  margin: 0;
  padding: 0;
}

.Dadupa-Nav .Nav-Item {
  display: inline-block;
  list-style-type: none;
}

.Dadupa-Nav .Nav-Link {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #2D363A;
  padding: 0 10px;
  transition: all .30s linear;
}

.Dadupa-Nav .Nav-Link:hover {
  color: #00b601;
  text-decoration: none;
}

.Dadupa-Nav .Active-Nav.Nav-Link {
  color: #00b601;
}

.Dadupa-Nav .Nav-Link .uil {
  /* color: #00b601; */
  font-size: 18px;
}

.Dadupa-Notifications {
  margin-right: 20px;
}

.Dadupa-Search {
  width: 180px;
  position: relative;
  margin-right: 10px;
}

.Dadupa-Search input {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  padding: 0 15px;
  border: 2px solid #FBFBFB;
  background: #FBFBFB;
  font-size: 13px;
  transition: all .20s linear;
}

.Dadupa-Search input:focus {
  border-color: #00b601;
  outline: none;
}

.Dadupa-Search button {
  background: transparent;
  padding: 0;
  position: absolute;
  top: 7px;
  right: 10px;
  border: none;
  font-size: 18px;
  color: #78909C;
}

.Dadupa-Notifications-Items {
  margin: 0;
  padding: 0;
  height: 70px;
  display: flex;
  align-items: center;
}

.Dadupa-Notifications-Items .Dadupa-Notifications-Item {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.Dadupa-Notifications-Items .Dadupa-Notifications-Item .Dadupa-Message,
.Dadupa-Notifications-Items .Dadupa-Notifications-Item .Dadupa-Alert {
  color: #78909C;
  font-size: 22px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  position: relative;
  background: transparent;
  padding: 0;
  border: none;
  position: relative;
}

.notifications-badge {
  background-color: #00b602;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  position: absolute;
  top: 7px;
  right: 7px;
}

.Dadupa-Notifs-Box {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  width: 350px;
  height: calc(100vh - 100px);
  overflow: hidden;
  border-radius: 15px;
  top: 50px;
  right: -100px;
  opacity: 0.0001;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
  padding: 20px;
}

.Dadupa-Notifs-Box::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  left: 0;
  right: -110px;
  margin: 0 auto;
}

.Dadupa-Alert-Popup .Notifs-Box-Active {
  opacity: 0.9999;
  transform: none;
  -webkit-transform: none;
  z-index: 999;
}

.Dadupa-Alert:hover .Dadupa-Notifs-Box {
  display: block;
}

.Dadupa-Notifs-Box h3 {
  font-size: 18px;
  font-weight: 900;
}

.Dadupa-Notifs-Box .Notif-Item {
  align-items: flex-start;
  /* padding: 8px; */
  border-top: 1px solid #F8F9F9;
  position: relative;
}
.Dadupa-Notifs-Box .Notif-Item a{
  text-decoration: none;
}
.Notif-Item-Inner{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 12px;
  /* border-radius: 15px; */
}
.Notif-Item:hover .Notif-Item-Inner{
  background-color: #f8f8f8;
}
.Notif-Thumb{
  flex: 0 0 55px;
  max-width: 55px;
}
.Notif-Thumb{
  border-radius: 15px;
  overflow: hidden;
}
.Notif-Thumb img{
  width: 100%;
  height: 55px;
  object-fit: cover;
}
.Notif-Content{
  flex: 0 0 calc(100% - 55px);
  max-width: calc(100% - 55px);
  padding-left: 15px;
}

.Notif-Options {
  position: absolute;
  top: 13px;
  right: 13px;
}

.Notif-Options button {
  color: #ABAFB1 !important;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 15px !important;
  background-color: #fff !important;
  visibility: hidden;
  opacity: 0;
}

.Notif-Item:hover .Notif-Options button {
  opacity: 1;
  visibility: visible;
}

.Notif-Options.show button {
  /* background: transparent !important; */
  /* color: #ABAFB1 !important; */
  font-size: 12px;
}

.Notif-Options .dropdown-menu.show {
  min-width: 100px;
  border-radius: 15px;
  padding: 0px 0;
  border: none;
  background: #F3FFF8;
  overflow: hidden;
}

.Notif-Options .dropdown-item {
  padding: 6px 14px 6px 10px;
  font-size: 12px;
  transition: all .30s linear;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #78909C;
}
.Notif-Options .dropdown-item i{
  font-size: 18px;
  width: 19px;
}
.Notif-Options .dropdown-item i.uil.uil-trash-alt{
  font-size: 16px;
}
.Notif-Options .dropdown-item i::before{
  margin: 0;
}

.Notif-Options .dropdown-item:hover {
  background: transparent;
  color: #2D363A;
}

.Notif-Options .dropdown-toggle::after {
  display: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}

.Dadupa-Notifs-Box .Notif-Item:first-child {
  border-top: none;
}

.Dadupa-Notifs-Box .Notif-Image {
  border-radius: 10px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.Dadupa-Notifs-Box .Notif-Image img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.Dadupa-Notifs-Box .Notif-Content {
  font-size: 12px;
  padding-left: 10px;
  padding-right: 35px;
}

.Dadupa-Notifs-Box .Notif-Text {
  color: #495057;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: 500;
  line-height: 19px;
  /* border-radius: 10px;
  overflow: hidden; */
}

.Dadupa-Notifs-Box .Notif-Time {
  font-size: 12px;
  color: #78909C;
  margin-top: 2px;
  font-weight: 500;
}

.new-message {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  background: red;
}

.new-notif {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 8px;
  right: 10px;
  background: red;
}

.Dadupa-Msgs-Box {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  width: 350px;
  height: calc(100vh - 100px);
  overflow: hidden;
  border-radius: 15px;
  top: 50px;
  right: -90px;
  opacity: 0.0001;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
  padding: 0 0px 20px;
}
.Dadupa-Notifs-Box-Header{
  padding: 12px 0;
}
.Dadupa-Msgs-Box::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  left: 0;
  right: -110px;
  margin: 0 auto;
}

.User-Connected {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00b601;
  position: absolute;
  top: 26px;
  right: 20px;
}

.Dadupa-Message-Popup .Msgs-Box-Active {
  opacity: 0.9999;
  transform: none;
  -webkit-transform: none;
  z-index: 999;
}

.Dadupa-Msgs-Box.Msgs-Box-Active .Msgs-List {
  overflow-y: auto;
  scrollbar-width: thin;
  height: calc(100vh - 100px);
  border-top: 2px solid #F8F9F9;
  padding-bottom: 50px;
}

.Messenger-List .Msgs-List {
  margin-top: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: calc(100vh - 170px);
  padding-bottom: 0;
}

.Dadupa-Msgs-Box.Msgs-Box-Active .Msgs-List::-webkit-scrollbar {
  width: 10px;
}

.Dadupa-Msgs-Box.Msgs-Box-Active .Msgs-List::-webkit-scrollbar-track {
  background: #F3FFF8;
}

.Dadupa-Msgs-Box.Msgs-Box-Active .Msgs-List::-webkit-scrollbar-thumb {
  background-color: #00b601;
  border-radius: 6px;
  border: 3px solid #F3FFF8;
}

.Messenger-List .Msgs-List::-webkit-scrollbar {
  width: 10px;
}

.Messenger-List .Msgs-List::-webkit-scrollbar-track {
  background: #F3FFF8;
}

.Messenger-List .Msgs-List::-webkit-scrollbar-thumb {
  background-color: #00b601;
  border-radius: 6px;
  border: 3px solid #F3FFF8;
}

.Dadupa-Msgs-Box h3 {
  font-size: 18px;
  font-weight: 700;
  padding: 0 15px;
  margin: 0;
}

.Messenger-List .Msgs-Item,
.Dadupa-Msgs-Box .Msgs-Item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  position: relative;
  width: 100%;
  border-top: 1px solid #F8F9F9;

}

.Messenger-List .New-Msg {
  font-weight: 700;
  color: #00b601;
}

.Dadupa-Msgs-Box .Msgs-Item:hover {
  cursor: pointer;
}

.Messenger-List .Msgs-Item {
  padding: 12px;
  background-color: transparent;
  transition: all .20s linear;
  border-radius: 15px;
}

.Messenger-List .Msgs-Item.active {
  background-color: #f2fff8;
}

.Messenger-List .Msgs-Item:hover {
  text-decoration: none;
}

.Dadupa-Msgs-Box .Msgs-Item:hover {
  text-decoration: none;
}

.Dadupa-Msgs-Box .New-Msg .Msgs-Text {
  color: #00b601;
  font-weight: 900;
}

.Dadupa-Msgs-Box .Msgs-Item:first-child {
  border-top: none;
}

.Dadupa-Msgs-Box .Msgs-Image {
  border-radius: 10px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.Messenger-List .Msgs-Image {
  border-radius: 10px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.Dadupa-Msgs-Box .Msgs-Image img {
  width: 100%;
}

.Messenger-List .Msgs-Image img {
  width: 100%;
}

.Dadupa-Msgs-Box .Msgs-Content {
  font-size: 12px;
  padding-left: 10px;
}

.Messenger-List .Msgs-Content {
  font-size: 12px;
  padding-left: 10px;
}

.Dadupa-Msgs-Box .Msgs-User {
  font-weight: 900;
  font-size: 14px;
  display: block;
  margin-bottom: 3px;
  color: #2D363A;
}

.Messenger-List .Msgs-User {
  font-weight: 900;
  font-size: 12px;
  display: block;
  margin-bottom: 3px;
  color: #2D363A;
}

.Dadupa-Msgs-Box .Msgs-Text {
  border-radius: 10px;
  overflow: hidden;
  font-size: 12px;
  color: #78909C;
}

.Messenger-List .Msgs-Text {
  border-radius: 10px;
  overflow: hidden;
  font-size: 12px;
  color: #78909C;
}

.Dadupa-Msgs-Box .Msgs-Time {
  font-size: 10px;
  color: #78909C;
  margin-top: 5px;
}

.Messenger-List .Msgs-Time {
  font-size: 10px;
  color: #78909C;
  margin-top: 5px;
}

.Dadupa-Msgs-Box .Friend-Active {
  position: absolute;
  right: 15px;
  font-size: 9px;
  bottom: 22px;
  width: 5px;
  height: 5px;
  background: #00b601;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: #fff;
}

.Messenger-List .New-Msg .Msgs-Text {
  color: #78909C;
}

.Messenger-List .Friend-Active {
  position: absolute;
  left: 8px;
  font-size: 9px;
  top: 8px;
  width: 12px;
  height: 12px;
  background: #00b601;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: #fcfcfc;
  border: 2px solid #fcfcfc;
}

.Msgs-N {
  position: absolute;
  bottom: 12px;
  right: 12px;
  background: #00b601;
  color: #fff;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 30px;
  font-weight: 300;
}

.Dadupa-Msgs-Box .Friend-Not-Active {
  position: absolute;
  right: 15px;
  font-size: 9px;
  bottom: 22px;
  width: 5px;
  height: 5px;
  background: #D5D5D5;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: #fff;
}

.Messenger-List .Friend-Not-Active {
  position: absolute;
  right: 15px;
  font-size: 9px;
  bottom: 22px;
  width: 5px;
  height: 5px;
  background: #D5D5D5;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: #fff;
}

.all-messages-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border: none;
  padding: 14.5px;
  border-top: 1px solid #f0f0f0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2D363A;
  text-align: center;
  text-decoration: none;
  transition: all .20s linear;
}

.all-messages-button:hover {
  text-decoration: none;
  color: #00b601;
}

.New-Post {
  margin-right: 10px;
  position: relative;
}

.New-Post .Add-New {
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #00b601;
  background: #00b601;
  color: #fff;
  font-size: 15px;
  padding: 0;
  /* line-height: 26px; */
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.New-Post .Add-New:hover {
  background: #F3FFF8;
  border-color: #00b601;
  color: #00b601;
}

.New-Post .Add-New i::before {
  margin: 0;
  width: auto;
}

.Dadupa-User {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
}

.Dadupa-User-Infos {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 40px;
  display: block;
}

.Dadupa-User-Infos:hover {
  cursor: pointer;
}

.Dadupa-User-Infos li {
  display: block;
  float: left;
  line-height: 40px;
}

.Dadupa-User .profile-image {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  overflow: hidden;
}

.Dadupa-User .profile-image img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.Dadupa-User .profile-name {
  color: #78909C;
  font-size: 12px;
}

.Dadupa-User .profile-arrow {
  margin-left: 5px;
}

.Dadupa-Mini-Profile {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  width: 250px;
  border-radius: 15px;
  top: 65px;
  right: 0;
  opacity: 0.0001;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
  visibility: hidden;
  padding-top: 10px;
}

.Dadupa-Mini-Profile::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 30px;
  margin: 0 auto;
}

.Mini-Profile-Active {
  opacity: 0.9999;
  transform: none;
  -webkit-transform: none;
  z-index: 999;
  visibility: visible;
}

.Dadupa-Popup-DropDown {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  /* width: 200px; */
  width: 280px;
  border-radius: 15px;
  top: 45px;
  right: -24px;
  opacity: 0.0001;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
}
.Dadupa-Mini-Profile-Meta{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px 10px;
  border-bottom: 2px solid #F8FBFC;
}
.Dadupa-Mini-Profile-Thumb{
  flex: 0 0 45px;
  max-width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
}
.Dadupa-Mini-Profile-Thumb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Dadupa-Mini-Profile-Infos{
  flex: 0 0 calc(100% - 45px);
  max-width: calc(100% - 45px);
  line-height: 1;
  padding-left: 10px;
}
.Dadupa-Mini-Profile-Infos span{
  font-size: 11px;
  color: #78909C;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.Dadupa-Popup-DropDown::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  left: 18px;
  margin: 0 auto;
}

.Dadupa-Popup-DropDown_Active {
  opacity: 0.9999;
  transform: none;
  -webkit-transform: none;
  z-index: 999;
}

.popup_project_details {
  top: 20px;
  left: 0;
  padding: 8px;
}

.project-popup-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.project-popup-item-avatar {
  width: 40px;
  height: 40px;
}

.project-popup-item-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}
.project-popup-item-username-inner{
  padding-left: 10px;
}
.project-popup-item-username-inner .project-popup-item-username{
  font-size: 14px;
  font-weight: 600;
}
.project-popup-item-username-inner .project-popup-item-profile{
  font-size: 12px;
  font-weight: 500;
}
.project-popup-item-username a * {
  margin: 0;
  margin-top: 0 !important;
}

.Mini-Profile-Name {
  color: #2D363A;
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Mini-Profile-Link {
  font-size: 12px;
  color: #78909C;
  text-decoration: none;
  padding: 0px 15px 10px;
  display: block;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.Mini-Profile-Link:hover {
  color: #00b601;
  text-decoration: none;
}

.Mini-Profile-Items {
  padding: 5px 0;
  border-bottom: 2px solid #F8FBFC;
  margin: 0;
}
.Mini-Profile-Items:last-child{
  border-bottom: 0;
}
.Mini-Profile-Items .Mini-Profile-Item {
  list-style-type: none;
  display: block;
}

.Mini-Profile-Items .Mini-Profile-Item:first-child {
  border-top: 0;
}

.Mini-Profile-Items .Mini-Profile-Item a {
  font-size: 12px;
  font-weight: 500;
  color: #2D363A;
  padding: 10px 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  transition: all .20s linear;
}

.Mini-Profile-Items .Mini-Profile-Item a.logoout-link .uil,
.Mini-Profile-Items .Mini-Profile-Item a.logoout-link{
  color: red;
  text-decoration: none;
}

.Mini-Profile-Items .Mini-Profile-Item a:not(.logoout-link):hover {
  color: #00b601;
  text-decoration: none;
}
.Mini-Profile-Items .Mini-Profile-Item a:not(.logoout-link):hover .uil{
  color: #00b601;
}

.Mini-Profile-Items .Mini-Profile-Item .uil {
  color: #78909C;
}

.Mini-Profile-Items .Mini-Profile-Item .uil::before {
  font-size: 18px;
  width: auto;
  height: auto;
  margin: 0;
}

.Filter-Form {
  background-color: #fff;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, .06);
  border-radius: 15px;
  padding: 10px 6px;
  margin-top: 45px;
}

.Filter-Form-Row{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Filter-Form-Row .display-flex{
  flex: 0 0 calc(100% - 250px);
  max-width: calc(100% - 250px);
}

.Filter-Form .input-row {
  /* width: 180px; */
  flex: 1 0 0;
  padding: 0 5px;
  margin: 0;
  display: inline-block;
}
.Filter-Form .input-row.filter-actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 250px;
  max-width: 250px;
  gap: 15px;
}

.Filter-Form .date-row {
  width: 420px;
}

.Filter-Form .input-row input[type="text"],
.Filter-Form .input-row input[type="email"],
.Filter-Form .input-row input[type="password"],
.Filter-Form .input-row input[type="tel"],
.Filter-Form .input-row select {
  border: 1px solid #F0F0F0;
}


.Filter-Form .input-row input[type="text"]:focus,
.Filter-Form .input-row input[type="email"]:focus,
.Filter-Form .input-row input[type="password"]:focus,
.Filter-Form .input-row input[type="tel"]:focus,
.Filter-Form .input-row select:focus {
  border-color: #00CC66;
  box-shadow: none;
  outline: none;
}

.input-flex {
  display: flex;
  align-items: center;
}

.input-flex label {
  margin-bottom: 0;
  margin-left: 12px;
}

.input-flex input[type="text"] {
  width: 60%;
}

.Filter-Form .form-button {
  width: 50px;
}

.filter-button {
  background-color: #00b601;
  border: 2px solid #00b601;
  border-radius: 15px;
  color: #fff;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  padding: 0 20px;
  font-size: 14px;
  gap: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.filter-button i{
  font-size: 18px;
  position: relative;
  left: -5px;}

.filter-button:hover {
  background-color: #03ac04;
  /* background: #F3FFF8;
  border-color: #00b601;
  color: #00b601; */
}

.Dadupa-Close {
  display: none;
}

.Logo-Responsive {
  display: none;
}

.Dadupa-Header-Mobile {
  display: none;
}

.Mobile-Search {
  display: none;
}

.Sidebar-Nav {
  display: none;
}

.Mobile-Filter {
  display: none;
}

.filter-button span {
  display: none;
}

.Dadupa-Message.Dadupa-MessengerBTN {
  display: none !important;
}

@media(max-width: 992px) {
  /*
  ########
  ## SIDEBAR MENU
  ########
  */

  .Dadupa-Message {
    display: none !important;
  }

  .Dadupa-Message.Dadupa-MessengerBTN {
    display: block !important;
  }

  .NoScroll {
    overflow: hidden;
  }

  .Sidebar-Nav {
    display: block;
    background: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    width: 280px;
    height: 100vh;
    z-index: 999;
    transition: all .30s linear;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
    overflow-y: scroll;
  }

  .Dadupa-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #2D363A;
    z-index: -1;
    transition: all .05s linear;
    opacity: 0;
  }

  .Dadupa-Overlay.Active-Overlay {
    z-index: 99;
    opacity: .9;
  }

  .Dadupa-Overlay .Dadupa-Close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    color: #fff;
    opacity: 1;
    z-index: 99;
    font-size: 20px;
    display: block;
  }

  .Active-Sidebar-Nav {
    left: 0;
  }

  .User-Profile {
    display: flex;
    padding: 30px;
    background: #fcfcfc;
  }

  .User-Profile .User-Details {
    margin-left: 15px;
  }

  .User-Profile .User-Name {
    display: block;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
  }

  .User-Profile .User-Image {
    border-radius: 10px;
    overflow: hidden;
    width: 40px;
    height: 40px;
  }

  .User-Profile .User-Profile-Link {
    display: block;
    font-size: 12px;
  }

  .User-Profile .User-Profile-Link {
    font-size: 12px;
    color: #78909C;
    text-decoration: none;
    padding: 0px;
    display: block;
  }

  .Mini-Profile-Link {
    font-size: 12px;
    color: #78909C;
    text-decoration: none;
    padding: 0px 15px 10px;
    display: block;
  }

  .Sidebar-Nav-Items {
    padding: 15px 0 15px;
    margin: 0;
  }

  .Sidebar-Nav-Items .Nav-Item {
    display: block;
    list-style-type: none;
  }

  .Sidebar-Nav-Items .Nav-Item a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #2D363A;
    padding: 10px 30px;
    transition: all .30s linear;
  }

  .Sidebar-Nav-Items .Nav-Item a:hover {
    color: #00b601;
    text-decoration: none;
  }

  .Sidebar-Nav-Items .Nav-Item .uil {
    color: #00b601;
    font-size: 18px;
  }

  .Sidebar-Nav .Mini-Profile-Item a {
    padding: 10px 30px;
  }

  .Sidebar-Nav .Mini-Profile-Items {
    padding: 15px 0 0;
    margin: 0;
    border-top: 3px solid #f7f7f7;
  }

  .Sidebar-Nav .Mini-Profile-Item {
    border-top: 0;
  }

  .Sidebar-Nav .Mini-Profile-Item .uil::before {
    width: 1em;
    margin-right: .2em;
  }

  .Add-New-Post {
    padding: 30px 30px 15px;
  }

  .Add-New-Post a {
    background-color: #00b601;
    color: #fff;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
  }

  .Add-New-Post a:hover {
    text-decoration: none;
  }

  .Add-New-Post .uil {
    font-size: 18px;
    position: relative;
    top: 2px;
  }

  .Add-New-Post .uil::before {
    width: auto;
    margin: 0 3px 0 0;
  }

  .Filter-Form {
    margin-top: 15px;
    padding: 15px 10px;
  }
}

@media(min-width: 992px) and (max-width: 1280px) {

  .Dadupa-Nav .Nav-Link .uil {
    display: none;
  }
}

@media(min-width: 769px) and (max-width: 992px) {
  .Filter-Form-Row{
    flex-direction: column;
  }
  .Filter-Form .input-row.filter-actions{
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Dadupa-Nav .Nav-Link {
    padding: 0 8px;
    font-size: 12px;
  }

  .Dadupa-Nav .Nav-Link .uil {
    display: none;
  }

  .display-flex {
    flex-wrap: wrap;
  }

  .Filter-Form .input-row {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }

  .Filter-Form .input-row:last-child {
    width: 100%;
    /* display: flex; */
    margin: 0;
  }

  .filter-button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-transform: uppercase; */
  }

  .custom-filter-btn{
    width: auto;
    padding: 0 20px;
    height: 45px;
  }
  .filter-button span {
    display: block;
    margin-left: 6px;
    font-weight: 700;
    font-size: 14px;
  }

  .filter-button i {
    font-size: 18px;
    position: relative;
    left: -6px;
  }
}

@media screen and (orientation:landscape) {

  .Dadupa-Notifs-Box,
  .Dadupa-Msgs-Box {
    /* height: 256px; */
  }

  .Dadupa-Notifs-Box .Notifs-List,
  .Dadupa-Msgs-Box .Msgs-List {
    /* height: 200px; */
  }

}

@media(max-width: 768px) {

  .Logo-Desktop {
    display: none;
  }

  .Logo-Responsive {
    display: block;
  }

  /* .New-Post {
    display: none;
  } */

  .Dadupa-Search {
    display: none !important;
  }

  .Dadupa-User .profile-name {
    display: none;
  }

  .Dadupa-User .profile-arrow {
    display: none;
  }

  .Dadupa-Header-Mobile {
    display: block;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);
  }

  .Dadupa-Mobile-TopItems {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
  }

  .left-nav {
    height: 70px;
  }

  .Dadupa-Mini-Profile {
    display: none;
  }

  .Dadupa-Mobile-Notifications a {
    color: #78909C;
    font-size: 22px;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    position: relative;
  }

  .Dadupa-Mobile-User {
    margin-left: 10px;
    border-radius: 5px;
    overflow: hidden;
  }

  .Dadupa-Mobile-User img {
    height: 40px;
    width: 40px;
  }

  .Mobile-Filter {
    display: block;
    position: relative;
    margin-top: 15px;
    display: flex;
  }

  .Mobile-Filter form {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .Mobile-Filter .full-search {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .Mobile-Filter input[type="search"] {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    padding: 0 15px 0 45px;
    border: 1px solid #F0F0F0;
    font-size: 13px;
    transition: all .20s linear;
  }

  .Mobile-Filter .search-button {
    background: transparent;
    padding: 0;
    position: absolute;
    top: 12px;
    left: 15px;
    border: none;
    font-size: 18px;
    color: #78909C;
  }

  .Mobile-Filter .Dadupa-Filter.Disable-Filter {
    display: none;
  }

  .Mobile-Filter .Dadupa-Filter {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    display: flex;
    justify-content: flex-end;
  }

  .Mobile-Filter .Dadupa-Filter button {
    width: 50px;
    height: 50px;
    background-color: #00b601;
    border: 2px solid #00b601;
    border-radius: 50%;
    color: #fff;
    transition: all .20s cubic-bezier(.65, .05, .36, 1);
    font-size: 22px;
  }

  .display-header-none {
    display: none;
  }

  .Filter-Row {
    margin-top: 15px;
    /* display: none; */
  }

  .Filter-Form-Row{
    display: block;
  }
  .Filter-Form .input-row.w300{
    width: 100% !important;
  }
  .Filter-Form .input-row {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .Filter-Form .input-row.filter-actions{
    margin-bottom: 0;
    margin-top: 15px;
  }

  .Filter-Form .display-flex {
    display: block;
  }

  .filter-button {
    width: 120px;
    height: 50px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    gap: 10px;
    font-weight: 500;
  }

  .filter-button span {
    display: block;
  }

  .Dadupa-Notifs-Box,
  .Dadupa-Msgs-Box {
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    top: unset;
    right: 0;
    bottom: -115%;
    transform: unset;
    transition: all .20s linear;
    /* transition: 0.3s cubic-bezier(.2, 0, 0, 1.6); */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 99;
    opacity: 1;
  }

  .Dadupa-Msgs-Box::before {
    display: none;
  }

  .Dadupa-Notifs-Box::before {
    display: none;
  }

  .Notifs-Box-Active,
  .Msgs-Box-Active {
    bottom: 0;
  }

  .Dadupa-Notifs-Box .Notif-Item {
    position: relative;
  }


}

/*
########
## OFFERS
########
*/

.page-header {
  margin-top: 60px;
  margin-bottom: 35px;
}

.page-header h3 {
  font-size: 24px;
  color: #2D363A;
  font-weight: 700;
}

.offers-list {
  margin-top: 30px;
}

.offer-box {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .03);
  margin-bottom: 30px;
}

.offer-header {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.offer-header .offer-title {
  max-width: 90%;
  flex: 0 0 90%;
}

.offer-header .offer-logo {
  max-width: 10%;
  flex: 0 0 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.offer-bookmark {
  height: auto;
  background-color: transparent;
  color: #78909C;
  font-size: 18px;
  padding: 0;
  border: none;
  line-height: 10px;
  transition: all .25s linear;
}

.offer-bookmark:hover {
  color: #00b601;
}

.offer-header .offer-logo img {
  width: auto;
  border-radius: 10px;
  margin-left: 10px;
  height: 40px;
  object-fit: contain;
}

.single-offer-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer-title h3 {
  color: #00b601;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
}

.offer-title h3 a,
.offer-title h3 {
  color: #00b601;
  text-decoration: none;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.offer-title {
  cursor: pointer;
}

.offer-box:hover .offer-title h3 a,
.offer-box:hover .offer-title h3 {
  color: #2D363A;
}

.offer-box .offer-title h3 span {
  color: #00b601;
  font-size: 14px;
  font-weight: 700;
  margin-top: 7px;
}

.offer-media video,
.offer-media img {
  width: 100%;
  height: auto;
}

.offer-media img {
  opacity: 1;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.thumb-play-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb-play-btn i {
  font-size: 50px;
  border: 3px solid #ffffff;
  border-radius: 50px;
  background: #4e4e4e4e;
  color: #ffffff;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
}

.offer-box:hover img {
  /* opacity: .8; */
}

.meta-items {
  margin: 0;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.meta-items .meta-item {
  list-style-type: none;
  display: flex;
  align-items: center;
  /* max-width: 50%;
  flex: 0 0 50%; */
}
.meta-items .meta-item.meta-col-1{
  max-width: 45%;
  flex: 0 0 45%;
}
.meta-items .meta-item.meta-col-2{
  max-width: 55%;
  flex: 0 0 55%;
}

.meta-item .meta-icon {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  background-color: #EBF9EB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #D2F2D2;
}
.meta-item .meta-icon img{
  height: 18px;
  object-fit: contain;
}

/* .bailleur .meta-items {
  justify-content: center;
} */

/* .bailleur .meta-items .meta-item {
  display: block;
  text-align: center;
} */

.bailleur .meta-item .meta-icon {
  margin: 0 auto;
}

/* .bailleur .meta-item .meta-details {
  padding: 0;
} */

.meta-item .meta-details {
  padding-left: 8px;
  overflow: hidden;
}

.meta-details .meta-title {
  display: block;
  font-size: 12px;
  color: #78909C;
  font-weight: 400;
    
}

.meta-details .meta-value {
  display: block;
  font-size: 12px;
  color: #2D363A;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
    
}

.Tags-List {
  padding: 0 15px;
  margin-bottom: 15px;
  text-align: center;
}



/* .offer-box.bailleur .meta-details .meta-value {
  margin-top: 5px;
} */

.MuiDialog-paper{
  border-radius: 15px !important;
  background-color: rgb(251 255 253);
}

.offer-reactions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  border-top: 1px solid #F8F9F9;
  padding: 0 15px;
}
.reactions-box {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactions-box .reaction {
  list-style-type: none;
  font-size: 12px;
  padding: 0 10px;
  line-height: 30px;
}
.reactions-box .reaction:first-child{
  padding-left: 0;
}

.reactions-box .reaction .uil {
  font-size: 16px;
  width: auto;
  height: auto;
  margin: 0;
}

.reactions-box .reaction.shares {
  cursor: pointer;
}

.offer-media {
  background: #00b601;
  position: relative;
  height: 230px;
}

:root {
  --plyr-color-main: #00b601;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  background: transparent;
  border: 2px solid #fff;
}

.plyr__control.plyr__control--overlaid svg {
  fill: transparent;
  stroke-width: 2;
  stroke: #fff;
}

.plyr--video {
  opacity: .9;
}

.plyr--video.plyr--playing {
  opacity: 1;
}

.Dadupa-Page .Dadupa-Footer {
  position: relative;
}

.plyr.plyr--stopped .plyr__controls,
.plyr--paused .plyr__controls {
  display: none;
}

/*
########
## FORM WIZARD
########
*/

#form-wizard,
.form-wizard {
  margin-top: 35px;
  position: relative;
}

.offer-wizard-wrapper .page-header {
  margin-top: 35px;
}

.offer-wizard-wrapper .page-header img {
  float: right;
}

.offer-wizard-wrapper .page-header p {
  font-size: 12px;
  line-height: 24px;
  color: #78909C;
}

.form-wizard fieldset {
  width: 100%;
  position: absolute;
}
.form-wizard fieldset .input-row{
  padding: 0 10px;
}
.form-wizard fieldset .input-row img{
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.form-wizard fieldset:not(:first-of-type) {
  display: none;
}

#wizardbar {
  display: flex;
  margin: 0;
  padding: 0;
}

#wizardbar li {
  list-style-type: none;
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 10px;
  font-size: 10px;
  flex: 0 0 25%;
  position: relative;
  color: #b5acac;
  font-weight: 500;
}

#wizardbar li::before {
  content: "";
  display: block;
  width: 70%;
  height: 1px;
  background-color: #B6C3C9;
  position: absolute;
  right: 8px;
  top: 13px;
  z-index: 1;
  transition: all .50s linear;
}

#wizardbar li::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: #B6C3C9;
  position: absolute;
  left: 48px;
  top: 13px;
  z-index: 2;
  transition: all .50s linear;
}

#wizardbar li:last-child::before {
  display: none;
}

#wizardbar .Step-Number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: #b5acac;
  border: 1.2px solid #b5acac;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  transition: all .20s linear;
}

#wizardbar li.active .Step-Number {
  background-color: #00b601;
  border-color: #00b601;
  color: #F3FFF8;
}

#wizardbar .Step-Number .uil {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0);
  transition: all .20s linear;
  font-size: 18px;
}

#wizardbar li.done .Step-Number .uil {
  opacity: 1;
  transform: scale(1);
}

#wizardbar li.done .Step-Number span {
  opacity: 0;
}

#wizardbar li.done::after {
  width: 70%;
  background-color: #00b601;
}

#wizardbar .Step-Title {
  font-size: 12px;
  background: #fcfcfc;
  padding-right: 8px;
  margin-left: 8px;
  position: relative;
  z-index: 9;
}

#wizardbar li.active .Step-Title {
  color: #00b601;
}

.wizard-fieldset .fieldset-header {
  display: none;
}

.wizard-fieldset-row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.wizard-fieldset-item{
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.photo-items-wrap{
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}

.gallery-item{
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.gallery-item img{
  border-radius: 15px;
}

.gallery-item img:hover{
  cursor: pointer;
}

.wizard-fieldset .input-row input[type="number"],
.wizard-fieldset .input-row input[type="url"],
.wizard-fieldset .input-row input[type="text"],
.wizard-fieldset .input-row input[type="email"],
.wizard-fieldset .input-row input[type="password"],
.wizard-fieldset .input-row input[type="tel"],
.wizard-fieldset .input-row select {
  border: 1px solid #F0F0F0;
  color: #2D363A;
}

.input-row input.error{
  border-color: red;
  color: red;
}

.User-Settings .input-row input[type="number"],
.User-Settings .input-row input[type="url"],
.User-Settings .input-row input[type="text"],
.User-Settings .input-row input[type="email"],
.User-Settings .input-row input[type="password"],
.User-Settings .input-row input[type="tel"],
.User-Settings .input-row select {
  border: 1px solid #F0F0F0;
  color: #2D363A;
}

.wizard-fieldset .input-row input[type="text"]:focus,
.wizard-fieldset .input-row input[type="email"]:focus,
.wizard-fieldset .input-row input[type="password"]:focus,
.wizard-fieldset .input-row input[type="tel"]:focus,
.wizard-fieldset .input-row select:focus {
  border-color: #00b601;
}

.User-Settings .input-row input[type="text"]:focus,
.User-Settings .input-row input[type="email"]:focus,
.User-Settings .input-row input[type="password"]:focus,
.User-Settings .input-row input[type="tel"]:focus,
.User-Settings .input-row select:focus {
  border-color: #00b601;
}

.wizard-fieldset .input-row input::-webkit-input-placeholder {
  color: #2D363A;
  opacity: 1;
}

.wizard-fieldset .input-row input::-moz-placeholder {
  color: #2D363A;
  opacity: 1;
}

.wizard-fieldset .input-row input:-ms-input-placeholder {
  color: #2D363A;
  opacity: 1;
}

.wizard-fieldset .input-row input:-moz-placeholder {
  color: #2D363A;
  opacity: 1;
}

.User-Settings .input-row input::-webkit-input-placeholder {
  color: #2D363A;
  opacity: 1;
}

.User-Settings .input-row input::-moz-placeholder {
  color: #2D363A;
  opacity: 1;
}

.User-Settings .input-row input:-ms-input-placeholder {
  color: #2D363A;
  opacity: 1;
}

.User-Settings .input-row input:-moz-placeholder {
  color: #2D363A;
  opacity: 1;
}

.wizard-fieldset .form-inputs {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .08);
  margin-top: 35px;
  margin-bottom: 30px;
  position: relative;
}

.User-Settings .form-inputs {
  background: #fff;
  padding: 30px 30px 25px;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .08);
  margin-bottom: 30px;
  max-height: 410px;
}

.User-Settings:first-child .form-inputs {
  /* margin-top: 35px; */
}

.User-Settings-Header {
  position: relative;
  margin-bottom: 30px;
}

.User-Settings-Header h3 {
  font-size: 18px;
  font-weight: 600;
}

.User-Settings-Header .UpdateInfos-BTN {
  top: 2px;
  right: 0px;
}

.User-Settings-Footer {
  margin-top: 15px;
  padding: 0 5px;
}

.confirmation-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 10px;
  height: 50px;
}

.confirmation-message-text {
  flex: 0 0 70%;
  max-width: 70%;
  font-size: 11px;
}

.confirmation-message-text p {
  margin: 0;
}

.confirmation-message-action {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: right;
}

.confirmation-message-action button {
  font-size: 11px;
  background-color: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
}

.custom-file {
  height: 50px;
}

.custom-file-input:hover {
  cursor: pointer;
}

.custom-file-input {
  height: 50px;
}

.custom-file .custom-file-label {
  border: 1px solid #F0F0F0;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  line-height: 48px;
}

.custom-switch {
  border: 1px solid #F0F0F0;
  width: 100%;
  height: 50px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  line-height: 48px;
}

.custom-switch .custom-control-label {
  width: 100%;
}

.custom-switch .custom-control-label:hover {
  cursor: pointer;
}

.custom-file-label::after,
.custom-file-input:lang(en)~.custom-file-label::after {
  content: '\eaed';
  font-family: 'unicons';
  background: transparent;
  border: none;
  font-size: 16px;
  top: 5px;
  right: 5px;
  color: #78909C;
}

.input-row .custom-switch .custom-control-label::before {
  left: unset;
  width: 40px;
  height: 20px;
  border-radius: 30px;
  top: 14px;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #03C854;
  border-color: #03C854;
}

.input-row .custom-switch .custom-control-label::after {
  left: 2px;
  width: 16px;
  height: 16px;
  top: 16px;
}

.input-row .custom-switch .custom-control-label span {
  margin-left: 50px;
}

/* .input-row .custom-control-label::before{
  right: -1.5rem;
  left: unset;
}
.input-row .custom-control-label::after{
  right: -1.5rem;
}*/
.custom-switch .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(1.20rem);
}

.input-status {
  position: relative;
  display: block;
  float: right;
}

.input-status::before {
  display: block;
  content: '\eb3a';
  font-family: 'unicons';
  font-style: normal;
  font-weight: 400;
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 18px;
}

.post-status {
  background: none;
  border: none;
  padding: 15px 50px 15px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 12px;
}

.wizard-fieldset .previous.action-button {
  width: auto;
  padding: 0 20px;
  background: #C0CBD0;
  border-color: #C0CBD0;
  display: flex;
  align-items: center;
}
.wizard-fieldset .previous.action-button i{
  font-size: 20px;
  position: relative;
  left: -8px;
}

.wizard-fieldset .draft-button i,
.wizard-fieldset .trash-button i {
  font-size: 24px;
}

.wizard-fieldset .submit.draft-button,
.wizard-fieldset .submit.trash-button {
  width: 50px !important;
  padding: 0 !important;
}

.wizard-fieldset .submit.action-button,
.wizard-fieldset .next.action-button {
  width: auto;
  padding: 0 20px;
  float: right;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.wizard-fieldset .submit.action-button i,
.wizard-fieldset .next.action-button i{
  font-size: 20px;
  position: relative;
  right: -8px;
}
.wizard-fieldset .previous.action-button {
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.wizard-fieldset .next.action-button:hover {
  color: #00CC66;
  background-color: transparent;
}

.wizard-fieldset .previous.action-button:hover {
  color: #C0CBD0;
  background-color: transparent;
}

.wizard-footer {
  display: flex;
  flex-wrap: wrap;
}

.wizard-footer .wizard-footer-left {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wizard-footer .wizard-footer-right {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.ck-editor__editable_inline {
  min-height: 210px;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.box.has-advanced-upload {
  background-color: white;
  outline: 2px dashed black;
  outline-offset: -10px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: grey;
}

.review-fieldset {
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding-left: 5px;
}


.review-fieldset::-webkit-scrollbar-track {
  background: #F3FFF8;
}

.review-fieldset::-webkit-scrollbar-thumb {
  background-color: #00b601;
  border-radius: 6px;
  border: 3px solid #F3FFF8;
}

.review-media {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.review-media video {
  width: 100%;
}

.review-content {
  margin-top: 20px;
}

.review-content p {
  font-size: 12px;
  line-height: 24px;
}

.review-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.funder.review-header,
.mentor.review-header{
  display: flex;
  flex-wrap: wrap;
}
.funder.review-header .review-header-left,
.mentor.review-header .review-header-left{
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.review-header-left .reeview-offer-logo{
  width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
}
.review-header-left .review-offer-title{
  font-size: 16px;
  margin: 0;
}
.review-header-left .reeview-offer-logo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.funder.review-header .review-header-right,
.mentor.review-header .review-header-right{
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: end;
}
.review-offer-meta{
  max-width: 60%;
  flex: 0 0 60%;
}
.review-offer-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0px;
}
.review-offer-website{
  position: relative;
  left: -5px;
}
.review-offer-logo {
  max-width: 40%;
  flex: 0 0 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.review-offer-logo img{
  width: 60px;
  height: auto;
}

.project-status {
  font-size: 10px;
  color: #78909C;
}

.business-sector {
  font-size: 10px;
  color: #78909C;
}

.review-meta {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.review-meta .review-meta-item {
  flex: 0 0 25%;
  max-width: 25%;
  text-align: left;

}

.review-meta .review-meta-item label {
  display: block;
  font-size: 11px;
  color: #78909C;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 5px;
}

.review-meta .review-meta-item span {
  display: block;
  font-size: 14px;
  color: #2D363A;
  font-weight: 700;
}

.review-tags {
  margin-top: 35px;
}

.review-tags h3 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.review-tags ul {
  margin: 0;
  padding: 0;
}

.review-tags li {
  display: inline-block;
  margin-right: 10px;
  background: #F3FFF8;
  border-radius: 15px;
  padding: 10px 15px;
  color: #252525;
  font-size: 12px;
}

.bootstrap-tagsinput {
  border: 1px solid #F0F0F0;
  width: 100%;
  height: 90px;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .02);
  padding: 6px 10px;
  font-size: 12px;
  transition: all .30s linear;
  line-height: 36px;
}

.bootstrap-tagsinput input[type="text"] {
  height: 30px;
  border: none;
  padding: 0;
}

.dadupa-tag {
  display: inline-block;
  margin-right: 5px;
  background: #F3FFF8;
  border-radius: 15px;
  padding: 0 15px;
  color: #252525;
  font-size: 10px;
  line-height: 30px;
}

.uppy-DragDrop-inner {
  padding: 115px 20px;
}

.form-images-uploaded{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.form-doc-item{
  flex: 0 0 25%; 
  /* height: 110px; */
}
.youtube-wrap{
  flex: 0 0 100%;
}
.youtube-wrap .form-doc-item-inner > div{
  border-radius: 10px;
  overflow: hidden;
}
.form-doc-item img{
  height: 100%;
  border-radius: 6px;
  object-fit: contain;
}
.form-doc-item-inner{
  padding: 0 8px;
}
.form-doc-item-inner .Doc-Wrap{
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
}
.form-doc-item-inner .Doc-Wrap .Doc-Icon{
  height: 120px;
}
.form-doc-item-inner .Doc-Wrap .Doc-Name{
  height: 50px;
  padding: 10px;
}
.form-doc-item-inner > div{
  width: 100% !important;
}

@media(min-width: 768px) and (max-width: 992px) {

  .offer-wizard-wrapper {
    height: 620px;
  }

}

@media(max-width: 768px) {
  .offer-box {
    margin-bottom: 15px;
  }

  .offers-list {
    margin-top: 15px;
  }

  .offer-wizard-wrapper .page-header img {
    display: none;
  }

  #form-wizard, 
  .form-wizard{
    margin: 15px 0 0;
  }
  #wizardbar {
    display: flex;
    margin: 0;
    padding: 0;
    /* position: absolute;
    width: 100%;
    top: 110px; */
  }

  #wizardbar li {
    display: block;
    text-align: center;
  }

  #wizardbar .Step-Number {
    margin: 0 auto 10px;
  }

  #wizardbar .Step-Title {
    margin: 0;
    padding: 0;
    display: none;
  }

  #wizardbar li::before {
    width: 50%;
    right: -25%;
  }

  #wizardbar li::after {
    left: 65px;
  }

  #wizardbar li.done::after {
    width: 50%;
    left: 75%;
  }

  .offer-wizard-wrapper .page-header {
    display: none;
    /* text-align: center;
    margin-top: 0;
    padding-top: 15px;
    margin-bottom: 20px; */
  }
  .page-header h3{
    font-size: 22px;
  }

  .fieldset-header {
    text-align: center;
  }
  .form-wizard fieldset{
    margin-top: 15px;
    /* padding-bottom: 120px; */
    position: relative;
  }
  .wizard-fieldset .fieldset-header {
    display: block;
    margin-bottom: 20px;
  }

  .wizard-fieldset .Step-Title {
    display: block;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    color: #00b601;
    margin-bottom: 5px;
  }

  .wizard-fieldset p {
    font-size: 12px;
    line-height: 18px;
  }

  .wizard-fieldset .form-inputs {
    max-height: 510px;
    padding: 15px 15px 15px;
    margin-top: 75px;
  }

  .input-tags,
  .input-row {
    padding: 0;
  }

  .custom-file {
    height: 50px;
  }

  .move-form {
    height: 500px;
  }

  .review-fieldset {
    padding: 0;
  }

  .review-meta .review-meta-item {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    margin-bottom: 15px;
  }

  .review-meta .review-meta-item label {
    margin-bottom: 5px;
  }

  .review-offer-title {
    font-size: 18px;
  }

  .review-tags li {
    margin-bottom: 10px;
  }

  .wizard-fieldset .submit.action-button,
  .wizard-fieldset .next.action-button {
    font-size: 12px;
    padding: 0 15px;
  }

  .wizard-fieldset .previous.action-button {
    font-size: 12px;
    padding: 0 15px;
  }
}

@media(max-width: 540px) {
  .mobile-heigth {
    height: 730px;
  }
}

@media screen and (orientation:landscape) {
  .mobile-heigth {
    /* height: 730px; */
  }
}

/*
########
## SINGLE OFFER
########
*/

.Single-Content {
  margin-top: 50px;
}

.single-page{
  margin-top: 30px;
}

.single-header {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.single-header .signle-offer-type {
  font-size: 12px;
  color: #78909C;
  margin: 0;
}

.Post-Actions {
  /* margin-top: 16px; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 10px;
}

.Send-Message {
  margin-left: 10px;
}

.Button-Send {
  background: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  padding: 12.5px 20px;
  border-radius: 30px;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.edit-button:hover,
.Button-Send:hover {
  background: #fff;
  color: #00b601;
}

.edit-button {
  background: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 12px;
  margin-top: 5px;
}

.permission-button {
  width: -webkit-fill-available;
  height: auto;
  border-radius: 1em;
  font-size: 2em;
}

.Content-Wrap {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
}

.single-offer-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.detail-page-breadcrumbs{
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -45px;
  font-size: 12px;
  color: #A5B7C0;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item{
  list-style-type: none;
  padding: 0 15px;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item::before{
  content: '\eb9f';
  font-family: 'unicons';
  font-style: normal;
  font-weight: 400;
  position: absolute;
  right: -6px;
  top: 2px;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item:first-child{
  padding-left: 0;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item:last-child{
  padding-right: 0;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item:last-child::before{
  display: none;
}
.detail-page-breadcrumbs .detail-breadcrumbs-item.current-item{
  color: #00b601;
  font-weight: 600;
}
.single-offer-name {
  font-size: 16px;
  font-weight: 700;
  color: #2D363A;
  margin: 0 0 5px;
}
.detail-page-header .detail-page-name{
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #00b601;
}
.detail-page-header .detail-page-user{
  font-size: 22px;
  font-weight: 600;
}
.single-offer-title{
  display: flex;
  align-items: center;
}
.single-offer-header .offer-bookmark {
  margin-left: 5px;
}

.single-offer-logo {
  height: auto;
  margin-right: 15px;
  width: 45px;
  height: 45px;
}


.Signle-Offer-Content {
  padding: 0 30px;
}

.reactions-wrap .reactions-box {
  display: block;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #F3F4F4;
}

.reactions-wrap .reactions-box .reaction {
  display: inline-block;
  font-size: 12px;
}

.reactions-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #F3F4F4;
}

.reaction-button {
  background-color: transparent;
  border: none;
  color: #78909C;
  font-size: 12px;
  /* margin: 0 30px; */
  padding: 0 30px;
  display: flex;
  align-items: center;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  outline: none;
}

.reaction-button i {
  margin-right: 5px;
}

.reaction-button:hover {
  text-decoration: none;
}

.reaction-clicked {
  animation: clicked linear 0.1s;
}

@keyframes clicked {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.reaction-button:focus {
  outline: none;
}

.comments-clicked,
.post-liked {
  color: #00CC66 !important;
}

.reaction-button img {
  height: 20px;
  margin-right: 8px;
}

.reaction-button i {
  color: #03C854;
  font-size: 22px;
}

.post-liked img {
  animation: like linear 0.8s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: like linear 0.8s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: like linear 0.8s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: like linear 0.8s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: like linear 0.8s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes like {
  0% {
    transform: translate(0px, 0px);
  }

  15% {
    transform: translate(0px, -25px);
  }

  30% {
    transform: translate(0px, 0px);
  }

  45% {
    transform: translate(0px, -15px);
  }

  60% {
    transform: translate(0px, 0px);
  }

  75% {
    transform: translate(0px, -5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes like {
  0% {
    -moz-transform: translate(0px, 0px);
  }

  15% {
    -moz-transform: translate(0px, -25px);
  }

  30% {
    -moz-transform: translate(0px, 0px);
  }

  45% {
    -moz-transform: translate(0px, -15px);
  }

  60% {
    -moz-transform: translate(0px, 0px);
  }

  75% {
    -moz-transform: translate(0px, -5px);
  }

  100% {
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes like {
  0% {
    -webkit-transform: translate(0px, 0px);
  }

  15% {
    -webkit-transform: translate(0px, -25px);
  }

  30% {
    -webkit-transform: translate(0px, 0px);
  }

  45% {
    -webkit-transform: translate(0px, -15px);
  }

  60% {
    -webkit-transform: translate(0px, 0px);
  }

  75% {
    -webkit-transform: translate(0px, -5px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes like {
  0% {
    -o-transform: translate(0px, 0px);
  }

  15% {
    -o-transform: translate(0px, -25px);
  }

  30% {
    -o-transform: translate(0px, 0px);
  }

  45% {
    -o-transform: translate(0px, -15px);
  }

  60% {
    -o-transform: translate(0px, 0px);
  }

  75% {
    -o-transform: translate(0px, -5px);
  }

  100% {
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes like {
  0% {
    -ms-transform: translate(0px, 0px);
  }

  15% {
    -ms-transform: translate(0px, -25px);
  }

  30% {
    -ms-transform: translate(0px, 0px);
  }

  45% {
    -ms-transform: translate(0px, -15px);
  }

  60% {
    -ms-transform: translate(0px, 0px);
  }

  75% {
    -ms-transform: translate(0px, -5px);
  }

  100% {
    -ms-transform: translate(0px, 0px);
  }
}

.PostFooter .Comments-Wrap {
  margin: 0;
}

.PostFooter .Comments-Box {
  box-shadow: none;
  margin: 0;
}

.Signle-Offer-Text {
  padding: 30px 0 10px;
  line-height: 22px;
  color: #474747;
}

.Signle-Offer-Text img{
  width: 100%;
}
.Signle-Offer-Text h1,
.Signle-Offer-Text h2,
.Signle-Offer-Text h3{
  margin-bottom: 20px;
}
.Signle-Offer-Text h2{
  font-size: 26px;
}
.Signle-Offer-Text h3{
  font-size: 22px;
}
.Comments-Wrap {
  margin-top: 20px;
}

.Comments-Header {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px 20px 10px;
}

.Comments-Filter,
.Comments-Title {
  flex: 0 0 50%;
  max-width: 50%;
}

.Comments-Title h3 {
  font-size: 22px;
  font-weight: 700;
  color: #2D363A;
}

.Comments-Filter {
  justify-content: flex-end;
  display: flex;
}

.comments-filter-select {
  background: #f8fbfc;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #78909C;
  font-size: 12px;
  padding: 10px 50px 10px 10px;
  border-radius: 30px;
}

.comments-filter-select:hover {
  cursor: pointer;
}

.comment-select {
  position: relative;
}

.comment-select::before {
  display: block;
  content: '\eb3a';
  font-family: 'unicons';
  font-style: normal;
  font-weight: 400;
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 16px;
}

.Comments-Box {
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
  padding: 10px;
  border-radius: 15px;
  margin-top: 15px;
}

.Comment-Writing {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #F8FBFC;
  margin-bottom: 0;
}

.User-Comments{
  margin-top: 10px;
}
.User-Comment {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.Comment-Col-10 {
  flex: 0 0 calc(100% - 35px);
  max-width: calc(100% - 35px);
  padding-left: 10px;
  position: relative;
}

.Comment-Reply .Comment-Col-10 {
  max-width: 90.667%;
}

.Comment-Col-2 {
  flex: 0 0 35px;
  max-width: 34px;
}

.Comment-User-Thumb {
  width: 35px;
  height: 34px;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
}

.Comment-User-Thumb img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

.Comment-Input input[type="text"] {
  background-color: #F8FBFC;
  border-radius: 30px;
  border: 2px solid #F8FBFC;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  font-size: 12px;
}

.Comment-User {
  background: #F8FBFC;
  padding: 10px;
  border-radius: 15px;
}

.Comment-User-Name {
  height: 25px;
  display: block;
}

.Comment-User-Name .Comment-User-Profile {
  color: #474747;
  font-size: 12px;
  font-weight: 700;
  float: left;
}

.Comment-User-Name .Comment-Date {
  color: #78909C;
  font-size: 12px;
  float: right;
}

.Comment-Text {
  font-size: 12px;
  line-height: 20px;
}

.User-Comment {
  margin-top: 5px;
}

.Comment-Replies {
  /* display: none; */
}

.Toggle-Replies {
  display: block;
}

.Comment-Reply {
  padding-left: 60px;
  width: 100%;
}

.Writing-Reply {
  display: none;
  padding-left: 60px;
  width: 100%;
  margin-top: 5px;
}

.Comment-Reply .Comment-Writing {
  margin-top: 0px;
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 5px;
  justify-content: space-between;
}

.comment-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.comment-replies-count,
.comment-actions.multi-options ul {
  flex: 0 0 50%;
  max-width: 50%;
}

.comment-replies-count {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
}

.comment-replies-button {
  background: none;
  border: none;
  padding: 0;
  display: block;
  outline: none;
}

.comment-replies-button:focus {
  outline: none;
}

.comment-replies-button:hover {
  cursor: pointer;
}

.comment-replies-count i {
  font-size: 16px;
}

.comment-replies-count label {
  margin: 0 0 0 4px;
}

.comment-reactions-list {
  margin: 0;
  padding: 0;
  text-align: center;
  margin: 10px 0 0;
}

.comment-reactions-list label {
  font-size: 12px;
}

.comment-reactions-list .comment-reaction {
  list-style-type: none;
  display: block;
}

.comment-reactions-list .comment-reaction img {
  height: 15px;
}

.comment-actions-list {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-right: 0;
  width: 100%;
  padding-top: 0px;
  line-height: 1;
}

.comment-actions-list .comment-action {
  display: inline-block;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  color: #78909C;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.comment-actions-list .comment-action .like-action {
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  color: #78909C;
  background: none;
  border: none;
  padding: 0;
}

.comment-actions-list .comment-action:hover {
  cursor: pointer;
  color: #00b601;
}

.comment-actions-list .comment-action.liked {
  color: #00b601;
}

.comment-actions-list .comment-action::after {
  content: ".";
  display: inline-block;
  padding-left: 8px;
  padding-right: 4px;
}

.comment-actions-list .comment-action:last-child::after {
  display: none;
}

.Single-Offer-Details {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
  position: relative;
}

.Offer-Details-List {
  margin: 0 -15px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.Offer-Details-List .Offer-Item:first-child{
  padding-bottom: 20px;
  padding-top: 0;
}
.Offer-Details-List .Offer-Item:first-child,
.Offer-Details-List .Offer-Item:last-child{
  flex: 0 0 100%;
  max-width: 100%;
  border-right: 0;
}
.Offer-Details-List .Offer-Item:last-child{
  padding-bottom: 0;
  border-bottom: 0;
  padding-top: 20px;
}
.Offer-Details-List .Offer-Item{
  flex: 0 0 50%;
  border-bottom: 1px dashed #F3F4F4;
  border-right: 1px dashed #F3F4F4; 
  padding: 10px 15px;
}
.Offer-Details-List .Offer-Item:nth-child(3),
.Offer-Details-List .Offer-Item:nth-child(5){
  border-right: 0;
}


.Offer-Details-List .Offer-Item {
  list-style-type: none;
  display: block;
  /* padding: 10px 0; */
  /* margin-bottom: 20px; */
}
.Offer-Details-List .Offer-Item .Contact{
  width: 100%;
}
.Offer-Details-List .Offer-Item .Contact a{
  display: block;
  width: 100%;
}
.Offer-Details-List .Offer-Item .Contact .Contact-Infos h4{
  text-overflow: unset;
  font-size: 16px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;   
  overflow: hidden;
  display: block; */
}
.projectOwner {
  color: rgba(0, 206, 97, 1)
}

.projectOwner:hover {
  color: #00b601;
  text-decoration: none;
}

.projectOwner img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-right: 0.5em;
}

.Offer-Details-List .Offer-Item label {
  display: block;
  font-size: 12px;
  color: #78909C;
  font-weight: 400;
  margin-bottom: 5px;
  font-style: italic;
}

.Offer-Details-List .Offer-Item span {
  display: block;
  font-size: 14px;
  color: #2D363A;
  font-weight: 600;
  line-height: 1;
}

.Offer-Details-List .Offer-Item:last-child {
  margin-bottom: 0;
}

.Single-Offer-Tags {
  padding: 20px 20px 15px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
  margin-top: 20px;
}

.Single-Offer-Tags h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #2D363A;
}

.Co-Porteurs {
  padding: 30px 30px 15px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
  margin-top: 30px;
}

.Co-Porteurs h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}

.Tags-List {
  margin: 0;
  padding: 0;
  text-align: left;
  /* display: flex;
  gap: 5px; */
}

.Tag-Item {
  display: inline-block;
  background: #F3F4F4;
  color: #78909C;
  font-size: 11px;
  font-weight: 500;
  border-radius: 5px;
  padding: 3px 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  /* box-shadow: 1px 1px 1px 1px #76aa8b; */
}

.Tag-Item:hover {
  /* display: inline-block; */
  background: #EEFAF3;
  /* font-size: 12px;
  border-radius: 5px;
  padding: 2px 5px;
  margin-bottom: 15px;
  margin-right: 5px; */
  /* box-shadow: 1.5px 1.5px 1.5px 1.5px #20cb67; */
  /* cursor: pointer; */
}

.Co-Porteurs {
  padding: 30px 30px 30px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
  margin-top: 30px;
}

.Co-Porteurs h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}

.Co-Porteurs-List {
  margin: 0;
  padding: 0;
}

.Co-Porteurs-List .Co-Porteur {
  display: block;
}

.Co-Porteurs-List .Co-Porteur:first-child a {
  padding-top: 0;
}

.Co-Porteurs-List .Co-Porteur:last-child a {
  padding-bottom: 0;
  border-bottom: 0;
}

.Co-Porteurs-List .Co-Porteur a {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  color: #78909C;
  padding: 15px 0;
  border-bottom: 1px solid #F6F6F6;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.Co-Porteurs-List .Co-Porteur a:hover {
  text-decoration: none;
  color: #00b601;
}

.Co-Porteur .Co-Porteur-Profile {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 15px;
}

@media(min-width: 768px) and (max-width: 992px) {

  .Signle-Offer-Content {
    padding: 0 15px;
  }

  .reaction-button {
    margin: 0;
    font-size: 14px;
    align-items: center;
  }

  .Signle-Offer-Text {
    padding: 10px 0;
  }

  .reaction-button img {
    height: 18px;
  }

  .PostHeader .PostUser-Time {
    font-size: 12px;
  }

  .Button-Send {
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .Single-Offer-Details {
    padding: 20px 20px 5px;
  }

  .Offer-Details-List .Offer-Item span {
    font-size: 14px;
  }

  .Single-Offer-Tags {
    padding: 20px 20px 15px;
  }

  /* .Tag-Item {
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 8px;
    margin-right: 2px;
  } */

  .Co-Porteurs {
    padding: 20px;
  }

  .Co-Porteurs-List .Co-Porteur a {
    padding: 10px 0;
  }
  .Offer-Details-List .Offer-Item{
    flex: 0 0 100%;
    border-left: 0;
    border-right: 0;
  }
  .Offer-Details-List .Offer-Item:last-child{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Offer-Details-List .Offer-Item .Contact .Contact-Infos h4{
    white-space: initial;
  }
}

@media(max-width: 768px) {

  .Signle-Offer-Content {
    padding: 0 15px;
  }
  .single-page{
    margin-top: 15px;
  }
  .single-header {
    margin-bottom: 20px;
  }
  .single-offer-title{
    margin-bottom: 5px;
  }
  .single-offer-name {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
    margin-left: 0px;
  }

  .reactions-wrap .reactions-box .col-6 {
    padding: 0 5px;
  }

  .reactions-wrap .reactions-box .reaction {
    font-size: 10px;
    padding: 0 5px;
  }

  .reaction-button {
    font-size: 10px;
    margin: 0 10px;
    padding: 0;
    line-height: 20px;
  }

  .reaction-button i {
    font-size: 18px;
  }

  .Signle-Offer-Text {
    padding: 15px 0;
    font-size: 12px;
    line-height: 18px;
  }
  .Signle-Offer-Text p:last-child{
    margin-bottom: 0;
  }

  .Comments-Box {
    padding: 10px;
  }

  .Comment-User-Thumb {
    width: 35px;
    height: 35px;
  }

  .Comment-Input input[type="text"] {
    height: 40px;
  }

  .Comment-Col-2 {
    flex: 0 0 13.333%;
    max-width: 13.333%;
  }

  .Comment-Col-10 {
    flex: 0 0 86.667%;
    max-width: 86.667%;
    padding-left: 10px;
  }

  .Comment-Writing {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .Comment-User-Name .Comment-Date {
    font-size: 10px;
  }

  .Update-Post {
    /* position: fixed;
    bottom: 75px;
    right: 15px;
    z-index: 99;
    margin: 0; */
  }

  .Send-Message {
    /* position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 99;
    margin: 0; */
    margin: 0;
  }

  .Send-Message span {
    display: none;
  }

  .Button-Send {
    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 22px;
  }
  .detail-page-breadcrumbs{
    top: 0;
    position: relative;
    padding-top: 10px;
    padding-bottom: 30px;
    justify-content: center;
  }

}

.Company-Infos {
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
  margin-bottom: 20px;
}

.Company-Infos.mentor .Company-Left,
.Company-Infos.funder .Company-Left{
  display: flex;
  gap: 10px;
}

.funder-meta .single-offer-name{
  display: flex;
  gap: 10px;
}

.funder-meta-list{
  display: flex;
  gap: 18px;
}
.funder-meta-list p{
  font-size: 12px;
  color: #78909C;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.funder-meta-list p i{
  font-size: 16px;
}
.funder-meta-list p a{
  color: #78909C;
}
.funder-meta-list p::before{
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  background-color: #78909C;
  border-radius: 50%;
  position: absolute;
  right: -14px;
  top: 11px;
}
.funder-meta-list p:last-child::before{
  display: none;
}

.Company-Left {
  flex: 0 0 60%;
  max-width: 60%;
}

.Company-Right {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.company-emta{
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 1;
}

.more-images-tab a{
  display: block;
  font-size: 12px;
  color: #78909C;
  font-weight: 500;
  margin-bottom: 0;
  transition: all .20s linear;
}

.more-images-tab a i{
  font-size: 18px;
}

.tab-title{
  font-size: 26px;
  font-weight: 600;
  color: #2D363A;
}

.images-wrap{
  margin-top: 45px;
}

.image-item img{
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .03);
}

.image-item img:hover{
  cursor: pointer;
}
.more-images-tab a:hover{
  text-decoration: none;
  color: #2D363A;
}

.Company-Infos .single-offer-logo {
  display: flex;
  align-items: flex-start;
  margin-right: 8px;
  width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
}

.Company-Infos .single-offer-logo img {
  height: 100%;
  width: 100%;
}
.project .review-offer-meta .review-offer-website{
  margin: 0;
}
.project .review-offer-meta .review-offer-website a{
  font-size: 14px;
  color: #00b601;
  font-weight: 500;
}
.funder.review-header .review-header-right .Company-Name a,
.mentor.review-header .review-header-right .Company-Name a,
.Company-Website a{
  font-size: 14px;
  font-weight: 500;
  color: #00b601;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}
.Company-Website a i{
  font-size: 18px;
}

.Company-Addresse {
  margin-top: 20px;
  /* padding-right: 55px; */
  /* line-height: 24px; */
}

.Company-Phone {
  
}

.Company-Phone a {
  color: #2D363A;
  display: flex;
  align-items: center;
  gap: 6px;
}
.Company-Phone span{
  font-size: 14px;
  font-weight: 500;
  color: #8399A4;
}
.Company-Phone i {
  font-size: 22px;
  color: #8399A4;
}

@media(min-width: 992px){
  .Company-Phone span{
    display: none;
  }
}

@media(min-width: 768px) and (max-width: 992px){
  .Company-Phone i{
    font-size: 16px;
  }
  .Company-Phone span{
    font-size: 12px;
    font-weight: 400;
  }
  .Company-Infos{
    position: relative;
  }
  .Company-Infos.mentor .Company-Left,
  .Company-Infos.mentor .Company-Right,
  .Company-Infos.funder .Company-Right,
  .Company-Infos.funder .Company-Left{
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start;
  }

  .Company-Infos.mentor .Company-Right,
  .Company-Infos.funder .Company-Right{
    padding-left: 52px;
    margin-top: 30px;
    position: initial;
  }
  .Company-Infos .single-offer-logo{
    margin-right: 0;
  }
  .Company-Infos .funder-meta-list{
    left: -4px;
    position: relative;
  }
  .PostOptions-BTN{
    position: absolute;
    top: 6px;
    right: 10px;
  }
  .Company-Website a i{
    font-size: 16px;
  }
  .Company-Website a{
    font-size: 12px;
    font-weight: 400;
  }
}

@media(max-width: 768px) {

  .single-offer-header{
    align-items: flex-end;
    margin: 0;
  }
  .Company-Infos {
    padding: 30px;
  }

  .Company-Left {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }

  .Company-Right {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .Company-Infos.funder .Company-Right,
  .Company-Infos.funder .Company-Left{
    flex: 0 0 100%;
    max-width: 100%;
  }

  .Company-Infos.funder .Company-Left .funder-meta{
    width: 100%;
  }

  .Company-Infos.funder .Company-Left .funder-meta .single-offer-name{
    justify-content: space-between;
  }

  .Company-Infos.funder{
    padding: 15px;
  }
  .Company-Infos.funder .Company-Right{
    margin-top: 20px;
    justify-content: flex-start;
  }

  .Company-Infos.funder .company-emta{
    justify-content: space-between;
    width: 100%;
  }

  .Company-Infos.funder .company-emta .Company-Website{
    line-height: 1;
  }

  .Company-Addresse {
    padding-right: 30px;
  }

  .Company-Phone {
    position: relative;
  }

  .Company-Website {
    position: relative;
    padding: 0;
  }

  .Company-Website a {
    padding: 0;
    display: block;
    text-align: center;
  }
}


/*
########
## USER PROFILE
########
*/

.Profile-Cover {
}

/* .Profile-Wrap::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00b601;
  opacity: .1;
  z-index: 9;
} */

.Profile-Wrapper {
  margin-top: 15px;
}

.Profile-Header {
  height: 280px;
  width: 100%;
  background: #00b601;
}

.Profile-Wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 280px;
  padding-bottom: 0;
  position: relative;
  z-index: 9;
  background-color: #F2F4F5;

  /* height: 192px; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.Profile-Infos {
  flex: 0 0 30%;
  max-width: 30%;
  position: relative;
  top: 55px;
}

.Profile-Infos label {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 18px;
  color: #00b601;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 82px;
}

.Profile-Picture {
  width: 100px;
  height: 100px;
  border: 2px solid #FCFCFC;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1); */
  background-position: center;
  background-size: cover;
}

.Profile-Skills ul {
  margin: 15px 0 0;
  padding: 0;
}

.Profile-Skills ul li {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 0px;
}

.Profile-Skills ul li span {
  /* padding: 8px 15px; */
  /* border-radius: 4px; */
  font-size: 12px;
  display: block;
  /* background-color: #e8fbf1; */
}

.Profile-Skills .delete-skill {
  position: absolute;
  top: -15px;
  right: -4px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: red;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.Profile-Skills ul li:hover .delete-skill {
  visibility: visible;
  opacity: 1;
}

.Profile-Skills .delete-skill::before {
  margin: 0;
}

.Profile-Picture img {
  width: 100%;
}

.Profile-Name {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #4c4c4c;
  /* text-shadow: 0 0 10px rgba(0, 0, 0, .2); */
}

.Profile-Icon {
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  background: #fff;
  color: #00b601;
  border-radius: 50%;
  line-height: 25px;
  margin-left: 6px;
  font-size: 16px;
}

#coverUpload,
#imageUpload {
  display: none;
}

.coverUpload {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, .8);
  padding: 2px 12px 8px;
  font-size: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: auto;
  margin: 0;
  gap: 8px;
}

.coverUpload:hover {
  cursor: pointer;
}

.coverUpload i {
  font-size: 22px;
  position: relative;
  /* top: 2px; */
}
.coverUpload span{
  font-size: 14px;
  font-weight: 600;
}
.Profile-Navigation {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  top: 0;
  position: relative;
  padding-top: 15px;
}

.Profie-Menu {
  padding: 0;
  margin: 0;
}

.Profie-Menu li {
  display: inline-block;
  margin-left: 10px;
}

.Profie-Menu li a {
  padding: 6px 15px 6px;
  background-color: #fff;
  font-size: 13px;
  color: #2D363A;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  position: relative;
}

.Discuss-Tab {
  width: 39px;
  height: 39px;
  padding: 0 !important;
  background: #00b601 !important;
  border: 2px solid #00b601;
}

.Discuss-Tab i {
  color: #fff !important;
  margin-right: 0 !important;
}

.Discuss-Tab i::before {
  margin-right: 0;
}

.Profie-Menu li a:hover {
  color: #00b601;
  text-decoration: none;
}

.Discuss-Tab:hover {
  background-color: #FFF !important;
}

.Discuss-Tab:hover i {
  color: #00b601 !important;
}

.NewRequests-Numbers {
  width: 20px;
  height: 20px;
  display: block;
  font-size: 10px;
  text-align: center;
  background: #00b601;
  color: #fff;
  border-radius: 50%;
  line-height: 20px;
  margin-left: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.Profie-Menu li a i {
  font-size: 18px;
  /* color: #00b601; */
  /* margin-right: 5px; */
}

.Profie-Menu li a i::before {
  margin-left: 0;
}

.Widget-BOX {
  /* background: #fff;
  box-shadow: 0 0 30px 0 rgba(0,0,0, .05);
  padding: 30px 20px;
  position: relative;
  border-radius: 15px; */
}

.Profile-Info {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .03);
  padding: 15px 15px 10px;
  position: relative;
  border-radius: 15px;
  margin-bottom: 15px;
}

.Profile-Info:last-child {
  margin-bottom: 0;
  margin-top: 0;
}
.social-media-fields ul{
  display: flex;
  flex-wrap: wrap;
}
.social-media-fields ul li{
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 10px;
}
.social-media-fields ul li:last-child{
  flex: 0 0 100%;
  max-width: 100%;
}
.social-media-fields ul li .form-row{
  width: 100%;
  padding: 0 5px;
}
.social-media-fields ul li .form-row .social-media-profile{
  width: 100%;
  position: relative;
}
.social-media-fields ul li .form-row .social-media-profile i{
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.social-media-fields ul li input{
  width: 100%;
  padding-left: 45px !important;
}
.Widget-Title,
.Profile-Info h3 {
  font-size: 14px;
  font-weight: 600;
  color: #37474F;
}

.Profile-Info span {
  /* font-size: 12px; */
  font-weight: 400;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Left-Side,
.Right-Side{
  position: sticky;
  top: 95px;
}

.Right-Side.fixed {
  /* position: fixed;
  bottom: 0;
  width: 255px; */
}

.Contact-Widget {
  padding: 15px 15px 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
  border-radius: 15px;
  margin-bottom: 15px;
}

.Widget-Conseils {
  padding: 15px 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
  border-radius: 15px;
  margin-bottom: 15px;
}

.Widget-Sponsored {
  padding: 15px 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
  border-radius: 15px;
}

.Conseils-Widget .Widget-Title,
.Contact-Widget .Widget-Title {
  margin-bottom: 30px;
}

.Contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.Contact .Profile-Icon {
  position: absolute;
  top: -8px;
  left: -6px;
  width: 18px;
  height: 18px;
  background: #2D363A;
  color: #fff !important;
  line-height: 18px;
  margin-left: 0;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.Contact div {
  /* display: inline-block; */
}

.Contact:last-child {
  margin-bottom: 0;
}

.Contact .Contact-Thumb {
  width: 30px;
  height: 30px;
  /* background: #2D363A; */
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 30px;
  max-width: 30px;
}

.Contact .Contact-Thumb img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: contain;
}

.Contact .Contact-Infos {
  padding-left: 5px;
  position: relative;
  padding-left: 10px;
  /* flex: 0 0 120px;
  max-width: 120px; */

  display: flex;
  flex: 0 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  /* padding-top: 12px; */
}
.Contact .Contact-Infos .Contact-Infos-Row{
  display: block;
  flex: 0 0 calc(100% - 26px);
  max-width: calc(100% - 26px);
  padding-right: 10px;
}
.Single-Offer-Details .Contact .Contact-Infos .Contact-Infos-Row{
  flex: unset;
  max-width: 100%;
}
.Contact-Infos-Row p{
  margin: 0;
  font-size: 10px;
  margin-top: 4px;
  font-weight: 500;
  position: relative;
  left: -4px;
  line-height: 1;
  color: #ABAFB1;
}
.Contact-Infos-Row p i{
  font-size: 14px;
}
.Contact .Contact-Infos h4 {
  font-size: 12px;
  font-weight: 600;
  color: #2D363A;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  max-width: 100%;
}

.Contact .Contact-Infos .Profile-Type {
  font-size: 10px;
  color: #859BA6;
}

.Contact .Add-Contact {
  /* flex: 0 0 50px;
  max-width: 50px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto; */
  flex: 0 0 26px;
  max-width: 26px;
}
.Contact .Add-Contact .Invitation-Options{
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-end;
}
.Contact .Add-Contact .Invitation-Options button{
  width: 16px;
  height: 16px;
  font-size: 14px;
}
.Contact .Add-Contact button {
  font-size: 16px;
  color: #00b601;
  border: 1px solid #00b601;
  border-radius: 30px;
  padding: 0;
  width: 26px;
  height: 26px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contact .Add-Contact.Invitation-Options button {
  width: 25px;
  height: 25px;
  font-size: 16px;
}

.Contact .Add-Contact.Invitation-Options .Invitation-Option_Confirm {
  border-color: #00b601;
  color: #00b601;
  margin-right: 6px;
}

.Contact .Add-Contact.Invitation-Options .Invitation-Option_Delete {
  border-color: red;
  color: red;
}

.Contact .Add-Contact button:hover {
  background: #00b601;
  color: #fff;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
}

.Contact .Add-Contact.Invitation-Options .Invitation-Option_Confirm:hover {
  border-color: #00b601;
  color: #fff;
  background: #00b601;
}

.Contact .Add-Contact.Invitation-Options .Invitation-Option_Delete:hover {
  border-color: red;
  color: #fff;
  background: red;
}

.Contact-SeeMore {
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2D363A;
  text-align: center;
  padding: 10px 0 0;
  margin-top: 15px;
  border-top: 1px solid #F0F0F0;
  transition: all .20s linear;
  outline: none;
}

.Contact-SeeMore:hover {
  color: #00b601;
}

.Widget-Conseils .Widget-Title {
  padding: 0 20px;
}

.Featured-Post-Thumb {
  width: 100%;
  height: 180px;
  /* background: #2D363A; */
  margin: 15px 0;
}

.Featured-Post-Thumb img {
  width: 100%;
  height: auto;
}

.Featured-Post-Content {
  padding: 0 20px;
}

.Featured-Post-Title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Featured-Post-Title a {
  font-size: 14px;
  font-weight: 700;
  color: #2D363A;
}

.Featured-Post-Meta ul {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  display: block;
}

.Featured-Post-Meta ul li {
  display: inline-block;
  font-size: 10px;
  color: #859BA6
}

.Widget-Sponsored .Widget-Title {
  padding: 0 20px;
}

.Sponsored-Post-Thumb {
  width: 100%;
  height: 180px;
  background: #2D363A;
  margin: 15px 0;
}

.Sponsored-Post-Content {
  padding: 0 20px;
}

.Sponsored-Post-Title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Sponsored-Post-Title a {
  font-size: 12px;
  font-weight: 700;
  color: #2D363A;
}

.Sponsored-Post-Meta a {
  font-size: 10px;
  color: #859BA6
}

.WritePost {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

.WritePost {
  background: #fff;
  padding: 20px 20px 15px;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
}
.WritePost-Area{
  display: flex;
  flex-wrap: wrap;
}
.WritePost-UserThumb {
  flex: 0 0 50px;
  max-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
}

.WritePost-UserThumb img {
  /* width: 100%;
  height: auto; */
  height: 100%;
  object-fit: cover;
}
.WritePost-TextArea-Wrap{
  flex: 0 0 calc(100% - 50px);
  max-width: calc(100% - 50px);
  padding-left: 15px;
}
.WritePost-TextArea {
  border: none;
  width: 100%;
  height: 60px;
  padding: 15px;
  overflow: hidden;
  outline: none;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.WritePost-Options {
  border-top: 1px solid #F2F2F2;
  padding-top: 15px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WritePost-Options button {
  background: none;
  border: none;
  font-size: 12px;
  transition: all .25s linear;
}

.Content-Options{
  display: flex;
  gap: 30px;
}
.Content-Options span{
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500  ;
}

.WritePost-Options button:hover {
  color: #00b601;
}

.WritePost-Options button i {
  font-size: 18px;
  color: #00b601;
}

@media(max-width: 768px) {

  .Contact .Contact-Infos {
    max-width: 70%;
    flex: 0 0 70%;
  }

  .Contact .Contact-Infos h4 {
    font-size: 14px;
  }

  .WritePost {
    padding: 10px;
  }

  .WritePost-UserThumb {
    top: 15px;
    left: 15px;
  }

  .WritePost-TextArea {
    height: 50px !important;
    padding: 10px 15px;
  }

  .WritePost-Options {
    display: none;
    padding-top: 10px;
  }

  .Profile-Info span {
    font-size: 14px;
    line-height: 26px;
  }

}

.PostWrap {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
  margin-top: 15px;
  position: relative;
}

.PostHeader {
  display: flex;
  align-items: center;
  padding: 20px;
}

.PostHeader .PostUser-Thumb {
  width: 50px;
  height: 50px;
  /* background-color: #000; */
  border-radius: 100%;
  overflow: hidden;
}

.PostHeader .PostUser-Thumb img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.PostHeader .PostUser-Details {
  margin-left: 15px;
  color: #474747;
}

.PostHeader .PostUser-Name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.PostHeader .PostUser-Time {
  font-size: 10px;
  color: #859BA6;
}

.PostOptions {
  position: absolute;
  top: 25px;
  right: 15px;
}

.PostOptions-BTN {
  width: 35px;
  /* height: 35px; */
  padding: 0;
  font-size: 26px;
  line-height: 1;
  background: transparent;
  border: none;
  color: #8399A4;
}

.PostOptions-BTN i {
  margin: 0;
}

.PostOptions-List {
  width: 130px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 9;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  padding: 0 10px;
  visibility: hidden;
  opacity: 0;
  transition: all .20s linear;
}

.PostOptions-List::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 15px;
  margin: 0 auto;
}

.PostOptions-List li {
  list-style-type: none;
}

.PostOptions-List button {
  padding: 6px 0px;
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  transition: all .20s linear;
}

.PostOptions-List button i {
  font-size: 16px;
  color: #00b601;
}

.PostOptions-List button:hover {
  color: #00b601;
}

.PostOptions-ListShow {
  visibility: visible;
  opacity: 1;
}

.PostBody {
  width: 100%;
}

.PostBody-Text {
  font-size: 12px;
  padding: 0 20px 0px;
  line-height: 19px;
}

.PostBody .PostBody-SingleImage img {
  width: 100%;
}

.PostBody-MultiImages {
  display: flex;
  flex-wrap: wrap;
}

.PostBody-MultiImage {
  max-width: 50%;
  flex: 0 0 50%;
  border: 1px solid #fff;
}

.PostBody-MultiImage img {
  width: 100%;
}

.PostBody-Text+[data-readmore-toggle] {
  font-size: 12px;
  color: #00b601;
  margin-left: 20px;
  margin-top: 0px;
}

.PostBody-Media {
  padding-top: 15px;
}

.PostWrap .reaction-button {
  margin: 0;
}

.PostWrap .reactions-box {
  padding: 0 10px;
}

.lg-outer .lg-inner {
  white-space: unset;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  text-align: left;
}

.lg-outer.fb-comments .lg-item.lg-complete .lg-img-wrap {
  text-align: center;
}

.fb-comments .Comments-Wrap {
  margin-top: 30px;
}

.fb-comments .Comment-User-Thumb {
  width: 30px;
  height: 30px;
}

.Profile-Sections {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .03);
  padding: 0 30px;
  margin-bottom: 15px;
}

.Profile-Section {
  padding: 16px 0;
  border-bottom: 2px solid #fcfcfc;
  position: relative;
}

.Profile-Section .Section-Items {
  padding: 0;
  margin: 20px 0 0;
}

.Profile-Section .Section-Items .Section-Item .section-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.Profile-Section .Section-Items .Section-Item .section-header .section-actions .Invitation-Options{
  display: flex;
  gap: 5px;
  flex-direction: row;
}
.Profile-Section .Section-Items .Section-Item .section-header .section-actions .Invitation-Options button{
  width: 25px;
  height: 25px;
}
.Profile-Section .Section-Items .Section-Item .section-header .section-actions .Invitation-Options .Invitation-Option_Delete{
  color: red;
  border-color: #ffeded;
  background-color: #ffeded;
}
.Profile-Section .Section-Items .Section-Item .section-header .section-actions .Invitation-Options .Invitation-Option_Confirm{
  color: #00b601;
  border-color: #eafeea;
  background-color: #eafeea;
}
.Section-Item .section-header .Contact .Add-Contact{
  flex: unset;
  max-width: unset;
}
.Profile-Section .Section-Items .Section-Item {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
  position: relative;
}
.Profile-Section .Section-Items .Section-Item .section-sector{
  font-weight: 500;
}
.Profile-Section .UpdateInfos-BTN {
  right: 0;
  top: 20px;
  font-size: 18px;
  color: #ABAFB1;
}

.Profile-Section .Section-Items .Section-Item .UpdateInfos-BTN {
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .20s linear;
}

.Profile-Section .Section-Items .Section-Item:hover .UpdateInfos-BTN {
  opacity: 1;
  visibility: visible;
}

.Profile-Section .Section-Items .Section-Item:last-child {
  margin-bottom: 0px;
}

.Profile-Section .Section-Items .Section-Item label {
  font-weight: 600;
  display: block;
  margin: 0;
}

.Profile-Section .Section-Items .Section-Item span {
  font-size: 12px;
  color: #859BA6;
  line-height: 18px;
  display: block;
}
.Profile-Section .Section-Items .Section-Item .section-sector{
  margin-bottom: 8px;
}
.Profile-Section-Title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #2D363A;
  display: flex;
  align-items: center;
  gap: 6px;
}

.Profile-Section-Title .uil {
  font-size: 22px;
}

.Profile-Section-Title .uil::before {
  margin-left: 0;
}

.Profile-Infos-Items {
  /* margin-bottom: 30px; */
}

.Profile-Infos-Items ul {
  padding: 0;
  margin: 0;
}

.Profile-Infos-Items li {
  list-style-type: none;
  display: flex;
  margin-bottom: 6px;
  font-size: 12px;
  align-items: center;
  gap: 5px;
}

.Profile-Infos-Items li a{
  color: #37474F;
}

.Profile-Infos-Items li i {
  color: #00b601;
  font-size: 18px;
}

.Profile-Infos-Items li i::before {
  margin-left: 0;
}

.Portfolio {
  position: relative;
}

.Portfolio-Item {
  position: relative;
  padding: 0 15px;
}

.Portfolio-Item .UpdateInfos-BTN {
  right: 23px;
  top: 8px;
}

.Portfolio-Item .Project-Thumb {
  width: 100%;
  height: 180px;
  background-color: #000;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.Portfolio-Item .Project-Name a {
  color: #2D363A;
  font-weight: 700;
  transition: all .30s linear;
}

.Portfolio-Item .Project-Name a:hover {
  color: #00b601;
  text-decoration: none;
}

.Portfolio .slick-arrow {
  padding: 0;
  border: none;
  font-size: 20px;
  background: #00b601;
  color: #fff;
}

.Portfolio .prevArrow {
  position: absolute;
  left: -30px;
  top: 35%;
  z-index: 9;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Portfolio .nextArrow {
  position: absolute;
  right: -30px;
  top: 35%;
  z-index: 9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media(min-width: 768px) and (max-width: 992px){
  .Profie-Menu li a{
    font-size: 12px;
    padding: 5px 10px;
  }
  .Profie-Menu li a i{
    font-size: 16px;
  }
}
@media(max-width:768px) {

  .Profile-Header {
    height: 200px;
  }

  .Profile-Wrap {
    display: flex;
    align-items: flex-end;
    height: auto;
    padding: 0;
    height: 200px;
  }
  .Profile-Cover{
    height: auto;
  }

  .Profile-Navigation,
  .Profile-Infos {
    max-width: 100%;
  }

  .Profile-Infos {
    /* text-align: center; */
    /* margin-bottom: 45px; */
    top: 70px;
  }


  .Profile-Infos label {
    left: 65px;
    right: unset;
    margin: 0;
  }

  .Profile-Picture {
    margin: 0;
    width: 80px;
    height: 80px;
  }

  .Profile-Name {
    font-size: 16px;
    margin-top: 15px;
    justify-content: flex-start;
  }

  .Profile-Navigation {
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
    margin-top: 85px;
    padding-top: 0;
  }

  .Profile-Wrapper {
    margin-top: 15px;

  }
  .Profie-Menu{
    width: 100%;
    padding: 5px;
  }
  .Profie-Menu li {
    margin: 0;
    flex: 1 0 0;
  }

  .Profie-Menu li a {
    font-size: 12px;
    padding: 5px 10px 5px;
    gap: 3px;
  }

  .Profie-Menu li a i {
    font-size: 16px;
  }

  .Profie-Menu li.Profie-Menu-Active a {
    color: #00b601;
  }

  .Discuss-Tab {
    position: absolute !important;
    top: 15px;
    left: 0;
  }

  .coverUpload {
    top: 15px;
    height: 30px;
    font-size: 18px;
    text-align: center;
    padding: 0 8px;
    gap: 6px;
  }
  .coverUpload span{
    font-size: 12px;
  }

  .coverUpload i {
    font-size: 20px;
  }

  /* .coverUpload span {
    display: none;
  } */

  .coverUpload i::before {
    margin-left: 0;
    margin-right: 0;
    width: auto;
    height: auto;
    line-height: 18px;
  }



  .PostWrap .reaction-button {
    margin: 0 15px;
  }

  .Contact-Widget,
  .Widget-Conseils,
  .Widget-Sponsored,
  .Profile-Bio {
    margin-bottom: 30px;
  }

  .Sponsored-Post-Thumb,
  .Featured-Post-Thumb {
    height: auto;
  }


  .Left-Side {
    margin-bottom: 30px;
  }

  .Profile {
    padding-top: 0;
  }

  .NewRequests-Numbers {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 5px;
  }

  .PostHeader {
    padding: 15px;
  }

  .PostOptions {
    top: 15px;
  }

  .PostBody-Text {
    padding: 0 15px 0;
    font-size: 14px;
  }

  .Profile-Infos-Items li {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .Profile-Sections {
    padding: 0 15px;
  }

  .Profile-Section {
    padding: 15px 0;
  }

  .Profile-Section .UpdateInfos-BTN {
    top: 15px;
  }

  .Profile-Section-Title {
    font-size: 16px;
  }

  .Profile-Section-Title .uil {
    font-size: 22px;
  }
}

.Networks {
  display: flex;
  flex-wrap: wrap;
}

.FriendBox-Item {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.FriendBox {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.FriendBox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgb(0, 206, 97);
  background: linear-gradient(0deg, rgba(0, 206, 97, .8) 0%, rgba(0, 0, 0, .2) 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.FriendBox-Delete {
  position: absolute;
  top: 8px;
  left: 40px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #fff;
  color: red;
  font-size: 18px;
  visibility: hidden;
  transform: scale(.8);
  opacity: 0;
  z-index: 9;
  transition: all .20s linear;
}

.FriendBox-Delete:hover {
  color: #fff;
  background-color: red;
  border-color: red;
}

.FriendBox-Accept {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #fff;
  color: #00b601;
  font-size: 18px;
  visibility: hidden;
  transform: scale(.8);
  opacity: 0;
  z-index: 9;
  transition: all .20s linear;
}

.FriendBox-Accept:hover {
  color: #fff;
  background-color: #00b601;
  border-color: #00b601;
}

.FriendBox:hover .FriendBox-Delete {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.FriendBox:hover .FriendBox-Accept {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.FriendBox a {
  display: block;
}

.FriendBox .FriendThumb img {
  transform: scale(1) rotate(0);
  transition: all .20s linear;
  width: 100%;
}

.FriendBox:hover .FriendThumb img {
  transform: scale(1.1) rotate(5deg);
}

.FriendBox .FriendInfos {
  z-index: 9;
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  text-align: center;
}

.FriendBox .FriendInfos h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
}

.FriendBox .FriendInfos span {
  font-size: 10px;
  line-height: 20px;
  color: #fff;
  display: block;
}

@media(max-width: 768px) {
  .FriendBox-Item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .Profie-Menu {
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    display: flex;
    white-space: nowrap;
    scrollbar-width: none;
    padding: 5px;
    /* Firefox */
  }

  .Profie-Menu::-webkit-scrollbar {
    display: none;
  }
}

.ModalShare-Content {
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  margin: 0 auto;
}

.ModalShare-Body .Share-Items {
  padding: 0;
  margin: 0;
  display: flex;
}

.ModalShare-Body .Share-Item {
  list-style-type: none;
  margin: 0 10px;
}

.ModalShare-Body .Share-Item:last-child {
  margin-right: 0;
}

.ModalShare-Body .Share-Item:first-child {
  margin-left: 0;
}

.ModalShare-Body .Share-Link {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 24px;
  line-height: 58px;
  border-radius: 50%;
}

.ModalShare-Header {
  margin-bottom: 15px;
}

.ModalShare-Header .ModalShare-Title {
  font-size: 20px;
  font-weight: 700;
}

.ModalShare-Header .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.Share-Facebook {
  background: rgba(24, 119, 242, .1) !important;
  border: 2px solid #fff;
  color: #1877f2 !important;
  transition: all .30s linear;
}

.Share-Facebook:hover {
  border-color: rgba(24, 119, 242, .1) !important;
  background-color: transparent;
}

.Share-Twitter {
  background: rgba(29, 161, 242, .1) !important;
  color: #1da1f2 !important;
  border: 2px solid #fff;
  transition: all .30s linear;
}

.Share-Twitter:hover {
  border-color: rgba(29, 161, 242, .1) !important;
  background-color: transparent;
}

.Share-Linkedin {
  background: rgba(10, 102, 194, .1) !important;
  color: #0a66c2 !important;
  border: 2px solid #fff;
  transition: all .15s linear;
}

.Share-Linkedin:hover {
  border-color: rgba(10, 102, 194, .1) !important;
  background-color: transparent;
}

.Share-Instagram {
  background: rgba(195, 42, 163, .1) !important;
  color: #c32aa3 !important;
  border: 2px solid #fff;
  transition: all .15s linear;
}

.Share-Instagram:hover {
  border-color: rgba(195, 42, 163, .1) !important;
  background-color: transparent;
}

.Share-Profile {
  background: rgba(0, 206, 97, .1) !important;
  color: #0ecd68 !important;
  border: 2px solid #fff;
  transition: all .15s linear;
}

.Share-Profile:hover {
  border-color: rgba(0, 206, 97, .1) !important;
  background-color: transparent;
}

.Share-Link:hover {
  cursor: pointer;
}

.ModalShare-CopyLink {
  margin-top: 30px;
}

.ModalShare-CopyLink h5 {
  font-size: 12px;
  margin-bottom: 20px;
}

.ModalShare-CopyLink form {
  position: relative;
}

.ModalShare-CopyLink input[type="text"] {
  border-radius: 15px;
  border: 2px solid #F6F6F6;
  width: 100%;
  height: 50px;
  padding: 15px;
  transition: all .15s linear;
}

.ModalShare-CopyLink input[type="text"]:focus {
  border-color: rgba(0, 206, 97, 1)
}

.ModalShare-CopyLink button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  font-size: 22px;
  background: #00b601;
  color: #fff;
  border: none;
  border-radius: 15px;
  width: 50px;
  transform: scale(0.8);
}

@media(max-width: 768px) {

  .ModalShare-Content {
    padding: 20px;
  }

  .ModalShare-Body .Share-Link {
    width: 55px;
    height: 55px;
    font-size: 24px !important;
    line-height: 48px;
  }

  .ModalShare-CopyLink {
    margin-top: 20px;
  }

  .ModalShare-Body .Share-Item {
    margin: 0 5px;
  }

}

/* MODAL */

.DadupaModal .modal-content {
  /* background-color: rgb(251 255 253); */
  /* background-color: #f2fff8; */
  border: none;
  border-radius: 10px;
}

.DadupaModal .modal-body {
  padding: 25px;
}
.DadupaModal .modal-body .modal-header{
  padding: 0 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
}
.DadupaModal .modal-body .modal-header .input-row{
  display: flex;
  align-items: center;
  margin: 0;
  gap: 10px;
}
.DadupaModal .modal-body .modal-header .input-row span{

}
.DadupaModal .modal-body .modal-header .input-row input{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.DadupaModal .modal-body .modal-header .input-row .custom-switch .custom-control-label::before{
  top: 0;
}
.DadupaModal .modal-body .modal-header .input-row .custom-switch .custom-control-label::after{
top: 2px;
}
.DadupaModal .modal-body .modal-header .input-row .custom-control-label{
  width: 40px;
}
.DadupaModal .modal-body .form-inputs .form-header{
  margin-bottom: 20px;
}
.DadupaModal .modal-body .form-inputs .form-modal-title{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.DadupaModal .modal-body .form-inputs .form-header p{
  color: #78909C;
}
.DadupaModal .modal-body .form-inputs .form-header p:last-child{
  margin-bottom: 0;
}
.DadupaModal .modal-body .form-inputs .form-row .input-row{
  margin-bottom: 15px;
}
.DadupaModal .modal-body .form-inputs .form-row .input-row label span{
  font-size: 12px;
  font-weight: 500;
}
.DadupaModal .modal-body .form-inputs .form-row .input-row:last-child{
  margin-bottom: 0;
}
.DadupaModal .modal-body .form-inputs .form-row .Send-Message{
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.DadupaModal .modal-body .form-inputs .form-row .form-check{
  margin-bottom: 5px;
}
.DadupaModal .modal-dialog {
  max-width: 600px;
}

.DadupaModal button.close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.DadupaModal-Header {
  padding: 30px 35px 0;
}

.DadupaModal-Header h4 {
  font-size: 20px;
  font-weight: 700;
}

.DadupaModal-BTNSubmit {
  display: block;
  width: auto;
  border: none;
  height: 40px;
  text-align: center;
  border-left: 1px solid #099b09;
  background: #00b601;
  color: #fff;
  font-size: 10px;
  padding: 0 35px;
  line-height: 36px;
  transition: all .20s cubic-bezier(.65, .05, .36, 1);
  text-transform: uppercase;
  font-weight: 700;
  float: left;
  position: relative;
  z-index: 9;
  border-radius: 40px;
}

.UpdateInfos-BTN {
  position: absolute;
  top: 8px;
  right: 8px;
  /* width: 25px;
  height: 25px; */
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 1;
  border: none;
  border-radius: 5px;
  color: #00b601;
  background: transparent;
  transition: all .20s linear;
}

.UpdateInfos-BTN:hover {
  color: #fff;
  background: #00b601;
}

.UpdateInfos-BTNText {
  width: auto;
  font-size: 12px;
  padding: 0 8px;
}

.CollapsUpdate {
  display: none;
  padding-top: 20px;
  padding-bottom: 25px;
}

.DadupaModal-Footer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.DadupaModal-Footer > .w-100{
  display: flex;
  justify-content: flex-end;
}

.DadupaModal-Footer .container-checkbox {
  margin: 0;
}

.DadupaModal-FooterCol {
  flex: 0 0 50%;
  max-width: 50%;
}

.DadupaModal-FooterColLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DadupaModal-FooterColRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CollapsUpdate input,
.CollapsUpdate select,
.CollapsUpdate textarea {
  border-color: #F6F6F6 !important;
}

.CollapsUpdate input:focus,
.CollapsUpdate select:focus,
.CollapsUpdate textarea:focus {
  border-color: #00b601 !important;
}

@media(max-width: 768px) {

  .DadupaModal-Header {
    padding-top: 15px;
  }

  .DadupaModal .modal-body {
    padding: 15px;
  }

  .DadupaModal button.close {
    top: 15px;
    right: 15px;
  }

}

/* NETWORK FILTER */

.Network-Header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Network-HeaderLeft {
  flex: 0 0 30%;
  max-width: 30%;
  display: flex;
  justify-content: flex-start;
}

.Network-HeaderRight {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  justify-content: flex-end;
}

.Network-HeaderTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.Network-HeaderLink {
  color: #00b601;
  margin: 0 8px;
  padding: 5px;
  font-size: 12px;
  transition: all .20s linear;
}

.Network-HeaderLink:hover {
  color: #2D363A;
  text-decoration: none;
}

.Network-HeaderLink:last-child {
  margin-right: 0;
}

.Network-HeaderLink:first-child {
  margin-left: 0;
}

.Network-Filter {
  margin-bottom: 35px;
}

.Network-Filter .Filter-Form {
  margin-top: 25px;
}

@media(max-width: 768px) {

  .Network-Header {
    padding-bottom: 15px;
  }

  .Network-HeaderLeft {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }

  .Network-HeaderRight {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 10px;
    justify-content: center;

  }

}

/* CREATE POST MODAL */

#SharingModal .modal-body{
  padding: 0;
}
#SharingModal .modal-content{
  border: none;
}
.CreatePost-Modal .modal-body {
  padding-top: 0;
}

.CreatePost-Modal .close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.CreatePost-Row {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.CreatePost-ColLeft {
  flex: 0 0 12%;
  max-width: 12%;
}

.CreatePost-ColRight {
  flex: 0 0 88%;
  max-width: 88%;
}

.CreatePost-UserThumb {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
}

.CreatePost-UserThumb img {
  width: 100%;
  height: auto;
}
.CreatePost-Body{
  padding-left: 15px;
  height: 100%;
}
.CreatePost-Body textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
}

.CreatePost-Option {
  background-color: transparent;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 15px;
  margin: 0;
  font-size: 20px;
  text-align: center;
  padding: 0;
  line-height: 30px;
  margin: 5px 0;
  border-width: 2px;
  border-style: solid;
  transition: all .20s linear;
  position: relative;
}

.CreatePost-Option input[type="file"]:hover {
  cursor: pointer;
}

.CreatePost-Option input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
}

.CreatePost-OptionDate {
  background-color: #E3F2FD;
  border-color: #E3F2FD;
  color: #1976D2;
}

.CreatePost-OptionDate:hover {
  background-color: transparent;
  color: #1976D2;
}

.CreatePost-OptionImage {
  background-color: #E8F5E9;
  border-color: #E8F5E9;
  color: #388E3C;
}

.CreatePost-OptionImage:hover {
  background-color: transparent;
  color: #388E3C;
}

.CreatePost-OptionVideo {
  background-color: #FFEBEE;
  border-color: #FFEBEE;
  color: #D32F2F;
}

.CreatePost-OptionVideo:hover {
  background-color: transparent;
  color: #D32F2F;
}

.CreatePost-OptionFile {
  background-color: #FFF3E0;
  border-color: #FFF3E0;
  color: #F57C00;
}

.CreatePost-OptionFile:hover {
  background-color: transparent;
  color: #F57C00;
}

.CreatePost-Option i::before {
  margint: 0;
}

.CreatePost-Option:first-child {
  margin-top: 0;
}

.CreatePost-Option:last-child {
  margin-bottom: 0;
}

.CreatePost-Options {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.CreatePost-AddTag {
  background-color: transparent;
  border: none;
  font-size: 12px;
  padding: 0;
  transition: all .20s linear;
}

.CreatePost-AddTag:hover {
  color: #00b601;
}

.CreatePost-Footer {
  padding: 0 20px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CreatePost-Footer .CreatePost-FooterLeft {
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-start;
}

.CreatePost-FooterLeft .input-row.input-select{
  width: 110px;
  margin: 0;
}

.CreatePost-Footer .CreatePost-FooterRight {
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
}

.CreatePost-PublishBTN {
  font-size: 14px;
  font-weight: 600;
  background-color: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  height: 40px;
  transition: all .20s linear;
}

.CreatePost-PublishBTN:hover {
  background-color: transparent;
  color: #00b601;
}

@media(max-width: 768px) {
  .CreatePost-Modal .modal-body {
    padding: 0;
  }

  .CreatePost-Row {
    padding: 15px 10px;
  }

  .CreatePost-ColLeft {
    flex: 0 0 15%;
    max-width: 15%;
  }

  .CreatePost-ColRight {
    flex: 0 0 85%;
    max-width: 85%;
  }

  .DadupaModal-Header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .DadupaModal-Header h4 {
    font-size: 18px;
  }

  .CreatePost-Modal .close {
    top: 15px;
    right: 15px;
  }
}

/* Settings UPDATE */

.ModalUpdate-Content {
  background-color: transparent;
  border: none;
}

.ModalUpdate-Content .User-Settings .form-inputs {
  max-height: none;
}

.ModalUpdate .User-Settings .form-inputs {
  margin: 0;
}

.ModalUpdate .User-Settings .form-inputs h4 {
  font-size: 14px;
  padding: 0 5px;
  margin-bottom: 15px;
  margin-top: 30px;
}

.ModalUpdate .User-Settings .form-inputs h4:first-child {
  margin-top: 0;
}

.Widget-BOX {
  margin-bottom: 30px;
}

.Widget-Stats {
  display: flex;
  /* justify-content: center;
  align-items: center;
  text-align: center; */
  margin-top: 20px;
}

.Stats-Field-Col-2 {
  flex: 0 0 50%;
  max-width: 50%;
}

.Stats-Value {
  color: #00b601;
}

.Stats-Value .counter {
  font-size: 16px;
  font-weight: 600;
}

.Stats-Value span {
  font-size: 20px;
}

.Stats-Text {
  font-size: 11px;
  line-height: 20px;
}

.no-deadline {
  color: #BDBDBD;
  font-size: 18px;
  margin: 0;
  padding: 0 2px;
  height: auto;
  line-height: 18px;
}

.existe-deadline {
  color: #00b601;
  font-size: 18px;
  margin: 0;
  padding: 0 2px;
  height: auto;
  line-height: 18px;
}

.near-deadline {
  color: #FB8C00;
  font-size: 18px;
  margin: 0;
  padding: 0 2px;
  height: auto;
  line-height: 18px;
}

.urgent-deadline {
  color: red;
  font-size: 18px;
  margin: 0;
  padding: 0 2px;
  height: auto;
  line-height: 18px;
}

@media(max-width:768px) {

  .User-Settings .form-inputs {
    margin: 15px 0 0;
    padding: 20px;
  }

  .User-Settings:first-child .form-inputs {
    margin: 15px 0 0;
  }

}

/* FAVORIS */

.Favoris-List {
  padding-top: 50px;
}

.Favoris-List .button-group {
  padding: 0 15px;
  margin-bottom: 35px;
}

.Favoris-List .button-group button {
  padding: 8px 15px;
  border-radius: 30px;
  background: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  margin: 0 5px;
}

.Favoris-List .button-group button:last-child {
  margin-right: 0;
}

.Favoris-List .button-group button:first-child {
  margin-left: 0;
}

.Favoris-List .PostWrap {
  margin-top: 0;
}
.notifications-grid{
  border: 1px solid #f8f8f8;
  border-bottom: none;
  overflow: hidden;
}
.notifications-grid:first-child{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.notifications-grid:last-child{
  border-bottom: 1px solid #f8f8f8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.notifications-grid .list-group-item{
  border: none;
}
.notifications-grid .Notifs-List{
  width: 100%;
}
.list-group-item-action:hover{
  background-color: #f8f8f8;
}
.Notif-Item a{
  width: 100%;
  margin: 0 !important;
  text-decoration: none;
}
.grid-sizer,
.grid-item {
  width: 50%;
  padding: 0 15px;
}

.grid-item {
  margin-bottom: 30px;
}

.grid-item .offer-box {
  margin-bottom: 0;
}

.MessengerMobile {
  display: none;
}

@media(max-width: 768px) {

  .MessengerMobile {
    display: block;
  }

  .MessengerDesktop {
    display: none;
  }

  .Favoris-List .button-group {
    text-align: center;
  }

  .Favoris-List {
    padding-top: 15px;
  }

  .grid-sizer,
  .grid-item {
    width: 100%;
    padding: 0 15px;
  }
}

/* PERFORMANCES */

.PerformanceModal .modal-dialog {
  max-width: 1070px !important;
}

.PerformanceModal .modal-content {
  background-color: #f2fff8;
  border: none;
}

.PerformanceModal .modal-body {
  padding: 20px 35px 35px;
}

.PerformanceModal .modal-dialog {
  max-width: 600px;
}

.PerformanceModal button.close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 9;
}

.PerformanceModal-Header {
  padding: 30px 35px 0;
}

.PerformanceModal-Header h4 {
  font-size: 20px;
  font-weight: 700;
}

.performance-cards {
  display: flex;
  flex-wrap: wrap;
}

.performance-col-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 5px;
}

.performance-col-2 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 5px;
}

.performance-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 5px;
}

.performance-section {
  margin-top: 20px;
}

.performance-section-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 0 5px;
}

.performance-card {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
}

.performance-card-value {
  font-size: 16px;
  font-weight: 700;
}

.performance-card-label {
  font-size: 11px;
  margin: 0;
  color: #78909C;
}

.PerformanceModal .offer-header {
  padding-left: 0;
  padding-right: 0;
}

.PerformanceModal .offer-title h3 {
  font-size: 20px;
}

@media(max-width: 768px) {

  .PerformanceModal .modal-body {
    padding: 15px;
  }

  .performance-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .performance-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .performance-card {
    padding: 10px 15px;
  }

  .PerformanceModal .offer-header {
    padding: 0px 15px 15px;
  }

  .PerformanceModal .offer-title h3 {
    font-size: 18px;
  }

  .PerformanceModal .offer-title span {
    margin-top: 5px;
  }

  .PerformanceModal .offer-footer {
    padding-top: 15px;
  }
}

.offer-title span {
  color: #78909C;
  font-size: 12px;
  display: block;
}

.offer-footer {
  display: flex;
  padding-top: 30px;
}

.offer-footer button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00b601;
  border: 2px solid #00b601;
  margin: 0 5px;
  font-size: 18px;
  padding: 0;
  color: #fff;
  transition: all .20s linear;
}

.offer-footer button:hover {
  color: #00b601;
  background-color: transparent;
}

.offer-footer button.offer-delete {
  background-color: red;
  border-color: red;
  color: #fff;
}

.offer-footer button.offer-delete:hover {
  background-color: transparent;
  border-color: red;
  color: red;
}

.offer-footer button:last-child {
  margin-right: 0;
}

.offer-footer button:first-child {
  margin-left: 0;
}

.offer-footer {
  display: flex;
  flex-wrap: wrap;
}

.offer-footer-left {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
}

.offer-footer-right {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
}

.popup-box {
  display: none;
  position: fixed;
  bottom: 0px;
  right: 0;
  background-color: #fafafa;
  width: 350px;
  z-index: 9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
}

.popup-box .popup-head {
  background-color: #2D363A;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  clear: both;
  height: 50px;
  display: flex;
  align-items: center;
}

.popup-box .popup-head .popup-head-left {
  float: left;
  width: 94%;
  cursor: pointer;
}

.popup-box .popup-head .popup-head-right {
  float: right;
  opacity: 1;
  display: flex;
  align-items: center;
}

.popup-box .popup-head .popup-head-right a {
  text-decoration: none;
  color: #fff;
  margin: 0px;
  font-size: 24px;
}

.popup-box .popup-head .popup-head-right a:first-child {
  margin-left: 0;
}

.popup-box .popup-head .popup-head-right a:last-child {
  margin-right: 0;
}

.popup-box .popup-messages {
  height: 320px;
  overflow-y: scroll;
  padding: 10px;
}

.popup-footer {
  position: relative;
  bottom: 0;
  width: calc(100% - 20px);
  margin: 0px auto 10px;
}

.popup-footer svg {
  width: 22px;
  height: 22px;
}

.popup-footer input[type="text"] {
  width: 100%;
  border: none;
  border-top: 1px solid #fff;
  background: #fff;
  height: 45px;
  padding: 0 15px 0 40px;
  font-size: 14px;
  border-radius: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
}

.popup-footer-actions {
  position: absolute;
  right: 2.5px;
  top: 4.5px;
  display: flex;
}

.button-send {
  width: 35px;
  height: 35px;
  background: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  border: none;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  margin-left: 5px;
}

.button-attachments {
  width: 35px;
  height: 35px;
  background: #f2fff8;
  border: 2px solid #00b601;
  color: #00b601;
  border: none;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.popup-footer .button-attachments {
  display: block;
}

.avatar-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #2D363A;
  display: inline-table;
}

.avatar-wrapper img {
  width: 100%;
}

.incoming-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.incoming-message .avatar-wrapper {
  border-bottom-right-radius: 0;
}

.outcoming-message .avatar-wrapper {
  border-bottom-left-radius: 0;
}

.outcoming-bubbles {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}

.incoming-bubbles {
  padding-left: 5px;
}

.outcoming-message {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  margin: 15px 0;
}

.incoming-message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: left;
  margin: 0 0 10px;
}

.bubble.bubble-dark {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bubble.bubble-dark .message-text {
  padding: 8px 10px;
  background: #c9f2be;
  color: #222;
  margin: 3px 0;
  align-self: flex-end;
  list-style-type: none;
  border-radius: 15px;
  font-size: 12px;
}

.message-status {
  font-size: 9px;
  padding-right: 5px;
  color: #78909C;
}

.message-status i {
  font-size: 15px;
  position: relative;
  top: 2px;
}

.message-seen .message-status i {
  color: #00b601;
}

.bubble.bubble-dark:last-child .message-text {
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

.bubble.bubble-light {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bubble.bubble-light .message-text {
  padding: 8px 10px;
  background: #fff;
  color: #2D363A;
  margin: 3px 5px 3px 0px;
  align-self: flex-end;
  list-style-type: none;
  border-radius: 15px;
  font-size: 12px;
}

.bubble.bubble-light:last-child .message-text {
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

.bubble.bubble-dark:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
}

.bubble.bubble-light:first-child {
  margin-top: 0;
}

.bubble.bubble-light:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
}

.popup-footer-attachments {
  position: absolute;
  right: 20px;
  z-index: 99;
  bottom: 48px;
  opacity: 0;
  visibility: hidden;
  transition: all .30s linear;
}

.popup-footer-attachments.popup-attachments-active {
  opacity: 1;
  right: 42px;
  visibility: visible;
}

.popup-footer-attachments .popup-attachment-item {
  display: block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  color: #fff;
  margin: 4px 0;
}

.popup-footer-attachments .popup-attachment-item:hover {
  cursor: pointer;
}

.popup-footer-attachments .popup-attachment-item input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.popup-footer-attachments .popup-attachment-item span {
  background: #00b601;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ### MESSENGER ### */

.Messenger-Wrapper {
  border-top: 1px solid #f5f5f5;
}

.Messenger-box {
  width: calc(100% - 30%);
  height: calc(100vh - 70px);
  background: #fcfcfc;
  border-left: 1px solid #f5f5f5;
}

.Messenger-user-profile {
  width: 30%;
  height: calc(100vh - 70px);
  background: #fcfcfc;
  border-left: 1px solid #f5f5f5;
  padding: 0 30px;
}

.Messenger-messages {
  height: calc(100vh - 200px);
  padding: 15px 15px;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.Messenger-messages::-webkit-scrollbar {
  width: 10px;
}

.Messenger-messages::-webkit-scrollbar-track {
  background: #F3FFF8;
}

.Messenger-messages::-webkit-scrollbar-thumb {
  background-color: #00b601;
  border-radius: 6px;
  border: 3px solid #F3FFF8;
}

.Messenger-row {
  width: 100%;
  overflow: hidden;
  /* box-shadow: 0 0 20px 0 rgba(0,0,0, .05); */
}

.Messenger-wrapper {
  display: flex;
}

.Messenger-footer {
  position: relative;
  bottom: 0;
  width: calc(100% - 20px);
  margin: 0px auto 0px;
}

.Messenger-footer svg {
  width: 25px;
  height: 25px;
}

.Messenger-footer input[type="text"] {
  width: 100%;
  border: none;
  border-top: 1px solid #fff;
  background: #fff;
  height: 45px;
  padding: 0 15px 0 45px;
  font-size: 12px;
  border-radius: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .03);
}

.Messenger-footer-actions {
  position: absolute;
  right: 4.5px;
  top: 4.5px;
  display: flex;
}

.button-send {
  width: 35px;
  height: 35px;
  background: #00b601;
  border: 2px solid #00b601;
  color: #fff;
  border: none;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  margin-left: 5px;
}

.button-attachments {
  width: 35px;
  height: 35px;
  background: #f2fff8;
  border: 2px solid #00b601;
  color: #00b601;
  border: none;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  display: none;
}

.Messenger-footer-attachments {
  position: absolute;
  right: 42px;
  z-index: 99;
  bottom: 5px;
  transition: all .30s linear;
  display: flex;
}

.Messenger-footer-attachments .Messenger-attachment-item {
  display: block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  color: #fff;
  margin: 0 2px;
}

.Messenger-footer-attachments .Messenger-attachment-item:hover {
  cursor: pointer;
}

.Messenger-footer-attachments .Messenger-attachment-item input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.Messenger-footer-attachments .Messenger-attachment-item span {
  background: #f2fbff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #2D363A;
  font-size: 18px;
}

.Messenger-head {
  /* background: #00b601; */
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-wrap: wrap;
}

.Messenger-head-left {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 0 70%;
  max-width: 70%;
  justify-content: flex-start;
}

.Messenger-head-right {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 0 30%;
  max-width: 30%;
  justify-content: flex-end;
}

.Messenger-head-user-thumb {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.Messenger-head-user-thumb img {
  width: 100%;
  height: auto;
}

.Messenger-head .Friend-Active {
  position: absolute;
  left: -5px;
  font-size: 9px;
  top: -5px;
  width: 15px;
  height: 15px;
  background: #00b601;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border: 3px solid #fff;
  z-index: 9;
}

.Messenger-head-user-info {
  font-size: 10px;
  padding-left: 8px;
}

.Messenger-head-user-info label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #2D363A;
}

.Messenger-head-user-info span {
  display: block;
  color: #00b601;
}

.Messenger-Search {
  height: 70px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Messenger-Search form {
  position: relative;
  width: 100%;
}

.Messenger-Search input[type="search"] {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  padding: 0 15px;
  border: 1px solid #F0F0F0;
  font-size: 13px;
  transition: all .20s linear;
}

.Messenger-Search button {
  background: transparent;
  padding: 0;
  position: absolute;
  top: 7px;
  right: 10px;
  border: none;
  font-size: 18px;
  color: #78909C;
}

.Messenger-List {
  padding: 0 15px;
}

.Messenger-ListHeader {
  display: flex;
}

.Messenger-ListHeaderButton {
  padding: 0;
  font-size: 20px;
  border: none;
  background: none;
  color: #78909C;
  width: 50px;
  transition: all .30s linear;
}

.Messenger-ListHeaderButton:hover {
  color: #00b601;
}

.Messenger-PreferencesBTN {
  padding: 0;
  font-size: 20px;
  border: none;
  background: none;
  color: #78909C;
  width: 50px;
  height: 70px;
  transition: all .30s linear;
}

.Messenger-PreferencesBTN:hover {
  color: #00b601;
}

.messages-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages-date-label {
  background: #2D363A;
  padding: 5px 12px;
  color: #fff;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 12px;
}

.Messenger-profile-header {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
}

.Messenger-Profile-Infos {
  position: relative;
}

.Messenger-Profile-Infos .Profile-Icon {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.Messenger-Profile-Infos .Profile-Picture {
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.Messenger-Profile-Infos .Profile-Name {
  color: #2D363A;
  text-shadow: none;
  padding-top: 12px;
  padding-bottom: 5px;
  font-size: 16px;
}

.Messenger-Profile-Infos .Profile-Function {
  font-size: 12px;
  color: #78909C;
}

.Messenger-Widget-Title {
  font-size: 14px;
  font-weight: 600;
  color: #2D363A;
  margin-bottom: 5px;
}

.Messenger-Profile-Widget p {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 15px;
  color: #8c909c;
}

.Messenger-Offers {
  margin-top: 15px;
}

.Messenger-Offer {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .04);
  padding: 5px;
  margin-bottom: 10px;
}

.Messenger-Offer:last-child {
  margin-bottom: 0;
}

.Messenger-Offer-Media {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: #000;
  margin-right: 10px;
}

.Messenger-Offer-Media img {
  width: 100%;
}

.Messenger-Offer-Content .offer-title span {
  font-size: 10px;
  margin-top: 5px;
}

.Messenger-Offer-Content .offer-logo {
  margin-bottom: 5px;
}

.Messenger-Offer-Content .offer-title h3 {
  font-size: 12px;
}

.Messenger-Offer-Content .offer-logo img {
  height: 30px;
}

.icon-clap::before {
  content: "";
  background: url('/assets/images/icons/dadupa-clap.svg') no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  right: 0;
  top: 4px;
  margin-right: 3px;
}

/* POST DOC */

.Doc-Wrap a {
  display: block;
  color: #fff;
}

.Doc-Wrap .Doc-Icon {
  aspect-ratio: 1/1;
  width: 100%;
  background: #2d363a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #fff;
  position: relative;
}

.Doc-Wrap .Doc-Name {
  width: 100%;
  height: 60px;
  color: #2D363A;
  background-color: #F8FDFF;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 15px;
  font-weight: 600;
}

.Doc-Wrap .Doc-Name i {
  font-size: 20px;
  margin-right: 2px;
  color: #00b601;
}

.Doc-Type {
  background: #fff;
  color: #2D363A;
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.Conversation-BTN {
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 26px;
  line-height: 35px;
  background: transparent;
  border: none;
}

.Conversation-BTN i {
  margin: 0;
}

.ConversationOptions-List {
  width: 150px;
  position: absolute;
  top: 40px;
  right: -5px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  padding: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
}

.ConversationOptions-List::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 15px;
  margin: 0 auto;
}

.ConversationOptions-List li {
  list-style-type: none;
}

.ConversationOptions-List button {
  padding: 6px 10px;
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  transition: all .20s linear;
}

.ConversationOptions-List button i {
  font-size: 16px;
  color: #00b601;
}

.ConversationOptions-List button:hover {
  color: #00b601;
}

.ConversationOptions-ListShow {
  visibility: visible;
  opacity: 1;
  transform: none;
  -webkit-transform: none;
  z-index: 9;
}

@media(max-width: 768px) {

  .Doc-Wrap .Doc-Icon {
    height: 280px;
  }

  .ConversationOptions-List {
    top: 45px;
  }

  .ConversationClose {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    margin-right: 8px;
    color: #333;
    font-size: 20px;
  }

  .Messenger-head-user-thumb {
    width: 40px;
    height: 40px;
  }

  .tab-content>.tab-pane {
    z-index: -1;
    position: relative;
  }

  .tab-content>.active {
    z-index: 9;
  }

  .Messenger-List {
    position: relative;
    z-index: 1;
  }
}

/* SEARCH MODAL */
.Search-Modal .close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.Search-Modal .Dadupa-Search {
  width: 100%;
  margin: 0 0 30px;
  padding: 0 10px;
}

.Search-Modal .Dadupa-Search input {
  height: 50px;
  border: 2px solid #F2F2F2;
}

.Search-Modal .Dadupa-Search button {
  top: 7px;
  right: 25px;
  font-size: 22px;
}

.Search-Modal .Networks {
  height: 290px;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.Search-Modal .Networks::-webkit-scrollbar {
  width: 10px;
}

.Search-Modal .Networks::-webkit-scrollbar-track {
  background: #F3FFF8;
}

.Search-Modal .Networks::-webkit-scrollbar-thumb {
  background-color: #00b601;
  border-radius: 6px;
  border: 3px solid #F3FFF8;
}

@media(max-width: 768px) {

  .Search-Modal .Dadupa-Search {
    display: block !important;
    margin: 0px 0 15px;
    padding: 0 0px;
  }

  .Search-Modal .DadupaModal-Header {
    padding-top: 20px;
  }

  .Search-Modal .close {
    top: 15px;
    right: 10px;
  }

  .Search-Modal .modal-content {
    border: none;
  }

}


.Preferences-List {
  width: 150px;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  padding: 10px;
  visibility: hidden;
  opacity: 0.0001;
  transform: scale(0.6) translateY(-20%);
  -webkit-transform: scale(0.1) translateY(-20%);
  z-index: -1;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
}

.Preferences-List::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 15px;
  margin: 0 auto;
}

.Preferences-List li {
  list-style-type: none;
}

.Preferences-List button {
  padding: 6px 10px;
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  transition: all .20s linear;
}

.Preferences-List button i {
  font-size: 16px;
  color: #00b601;
}

.Preferences-List button:hover {
  color: #00b601;
}

.Preferences-ListShow {
  visibility: visible;
  opacity: 1;
  transform: none;
  -webkit-transform: none;
  z-index: 9;
}

@media(max-width: 768px) {

  #vmobile-pills-tabContent {
    position: absolute;
    top: 0;
    left: 0;
  }

  .Messenger-box {
    width: 100%;
    height: 100vh;
  }

  .Messenger-user-profile {
    width: 100%;
    display: none;
  }

  .Messenger-messages {
    height: calc(100vh - 120px);
  }

  .messages-date {
    margin: 15px 0;
  }

  .messages-date-label {
    margin: 0;
  }

  .lg-outer.fb-comments .lg-item.lg-complete .lg-img-wrap {
    height: 50vh;
    padding: 0 !important;
  }

  .lg-outer.fb-comments .fb-comments {
    position: relative;
    width: 100%;
    height: 50vh;
  }

}

button .spinner-border {
  margin-left: 6px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.MuiSkeleton-text {
  transform: none !important;
  margin-bottom: 20px !important;
}

.input-multi-filter button {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  box-shadow: unset;
  border: 1px solid #F0F0F0;
  padding: 0 15px;
  font-size: 12px;
  transition: all .30s linear;
  background-color: #fff;
  box-shadow: unset;
}

.css-1jllj6i-control {
  min-height: 35px;
  height: 35px;
}

.custom-filter-btn {
  /* height: 40px; */
}

.Filter-Form .form-button {
  float: left;
}

.DadupaModal-BTNSubmit::after,
.DadupaModal-BTNSubmit::before {
  float: right;
  width: 40px;
  height: 40px;
  content: ' ';
  position: absolute;
  background: #00b602;
  top: 0;
  border-radius: 50%;
  z-index: -2;
  display: none;
}

.DadupaModal-BTNSubmit::after {
  right: -20px;
}

.DadupaModal-BTNSubmit::before {
  left: 0;
}

.mt-10 {
  margin-top: 10px;
}

.input-tags {
  width: 100% !important;
}

.tags-input {
  border: none !important;
  padding: 0 !important;
}

.Filter-Form .input-row.w300 {
  width: 220px;
}

.tags-input input {
  width: 190px !important;
  flex: none !important;
  padding: .5em !important;
}

.tags-input input::placeholder {
  font-size: 12px;
}

.tags-input input:focus {
  border-bottom: 1px solid #00CC66 !important;
}

.offer-title>img {
  float: left;
  margin-right: 10px;
  border-radius: 10px;
  max-width: 54px;
  width: 49px !important;
  height: 39px !important;
  border: 1px solid white;
}

.footer-title {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 8px;
    
}

.footer-title a {
  font-size: 12px;
  color: #505050;
}

.footer-title span {
  font-size: 12px;
}
.offer-title span.offer-sector-name{
  background-color: #00b60114;
  border-radius: 30px;
  color: #86D786;
  padding: 2px 8px;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #D2F2D2;
    

}
.offer-title .offer-company-name{
  display: flex;
  gap: 10px;
  align-items: center;
}
.offer-title .offer-company-name span:first-child{
  color: #2D363A;
  font-weight: 600;
}
.footer-title .footer-item{
  position: relative;
}
.footer-title .footer-item::before{
  content: '';
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  right: -10px;
  top: 1px;
  bottom: 0;
  margin: auto 0;
  background-color: #78909c;
  border-radius: 50%;
}
.footer-title .footer-item .uil::before{
  margin: 0;
}
.footer-title .footer-item .uil.uil-bell{
  position: relative;
  left: -4px;
}
.footer-title .footer-item:last-child::before{
  display: none;
}
.footer-item-name span{
  font-weight: 600;
}
.footer-item-flex{
  display: flex;
  color: #78909c;
  align-items: center;
  gap: 5px;
}
.footer-title .footer-item svg{
  height: 13px;
}
.mr-5 {
  margin-right: 5px !important;
}

.footer-title span i {
  font-size: 15px;
}

.list-group .Notif-Image {
  float: left;
  margin-right: 20px;
  border-radius: 10px !important;
  overflow: hidden;
}

.list-group .Notif-Text {
  color: #495057;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: 500;
  line-height: 19px;
}

.list-group .Notif-Options {
  text-align: right;
}

.list-group button.btn {
  font-size: 20px;
}

.list-group .Notif-Content {
  color: #838383;
}

.dadupa-box {
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 3px rgb(0 0 0 / 5%);
  border-radius: 15px;
}

.dadupa-box .list-group-item {
  border: none !important;
  background: transparent !important;
}

.dadupa-box .list-group {
  border-bottom: 1px solid #eeeeee;
}

.dadupa-box .Notif-Options .dropdown-menu.show,
.notification-list-menu .Notif-Options .dropdown-menu.dropdown-menu-right {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.dadupa-box .Notif-Options .dropdown-item {
  font-size: 13px;
}

.dadupa-box .Notif-Options .dropdown-item:nth-child(1),
.notification-list-menu .Notif-Options .dropdown-menu.dropdown-menu-right .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #eeeeee;
}

.dadupa-box .Notif-Options .dropdown-item i {
  font-size: 16px;
}

.dadupa-box .Notif-Options .dropdown-item i.uis-check {
  font-size: 19px;
}

.Dadupa-Notifs-Box .Notifs-List {
  padding: 0;
  border-bottom: 1px solid #f8f8f8;
}

.notification-list-menu .Notif-Options button {
  font-size: 20px;
  color: #ABAFB1;
}

.notification-list-menu .Notif-Options .dropdown-menu.dropdown-menu-right {
  right: 10px;
}

.mt-45 {
  margin-top: 45px;
}

.menu-settings {
  padding: 0;
}

.menu-settings.Contact-Widget .Widget-Title {
  border-bottom: 1px solid #ccc;
  padding: 0px;
  margin: 0;
  color: #4d4b4b;
  font-weight: 100;
}

.menu-settings.Contact-Widget .Widget-Title a {
  color: #4d4b4b;
  padding: 20px;
  display: block;
}

.menu-settings.Contact-Widget .Widget-Title a:hover {
  text-decoration: none;
}

.menu-settings.Contact-Widget .Widget-Title:last-child {
  border: none;
}

.menu-settings.Contact-Widget .Widget-Title a.Active-Nav {
  font-weight: 600;
  color: #00b602 !important;
}

.sub-menu-settings {
  margin-top: 0px;
  font-size: 13px;
  display: none;
}

.menu-settings.Contact-Widget .Widget-Title .sub-menu-settings a {
  padding: 15px 20px;
  border-radius: 0;
}

.menu-settings.Contact-Widget .Widget-Title .sub-menu-settings a.active {
  background: rgb(213 238 210 / 38%);
}

.menu-settings.Contact-Widget .Widget-Title a.Active-Nav+.sub-menu-settings {
  display: block;
}

.custom-btn-table-action {
  margin: 0 auto;
}

.custom-btn-table-action.New-Post .Add-New {
  margin: 0 auto;
}

.custom-btn-table-action button {
  border: none;
  color: #00b602;
  font-size: 22px;
  background: transparent;
  padding: 0;
}

.custom-btn-table-action .Dadupa-Popup-DropDown {
  width: 100px;
  top: 102px;
  right: 30px;
}

.custom-btn-table-action .Dadupa-Popup-DropDown .Mini-Profile-Item a {
  font-size: 13px;
  padding: 8px 4px;
}

.MuiTableContainer-root {
  background: #fff;
  border-radius: 14px;
  min-height: 35vh;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
}

.friends-section .nav-tabs .nav-link.active,
.friends-section .nav-tabs .nav-item.nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none;
  border-radius: 50px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 3%);
  margin-right: 20px;
}

.friends-section .nav-tabs .nav-link.active {
  color: #fff;
  background: #00b602;
}

.friends-section .nav-tabs {
  border: none;
}

.DadupaModal-BTNSubmit.advenced-btn-search {
  padding: 0;
  line-height: 1;
  background-color: transparent;
  color: #78909C;
  border-radius: 0;
  text-transform: initial;
  border: 0;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0px;
  /* border-bottom: 1px solid #78909C; */
  padding: 0;
}
.DadupaModal-BTNSubmit.advenced-btn-search:hover{
  /* background-color: #2D363A; */
  /* color: #fff; */
}

.DadupaModal-BTNSubmit.advenced-btn-search .uil{
  font-size: 16px;
  position: relative;
  left: -6px;
}
.DadupaModal-BTNSubmit.advenced-btn-search .uil::before{
  line-height: 0;
}

.Network-Filter .Filter-Form .input-row {
  width: 33.3333%;
}

.btn.btn-custom-order {
  background: transparent;
  border: none;
  color: #00b602;
  font-weight: 600;
}

button.btn-custom-order.btn.btn-secondary.btn-sm.dropdown-toggle {
  background: transparent;
  color: #00b602;
}

.lh40 {
  line-height: 40px;
}

.Network-Filter .Filter-Form .dropdown-item {
  font-size: 13px;
  line-height: initial;
  cursor: pointer;
}

.Network-Filter .Filter-Form .dropdown-item .dropdown-item.active,
.dropdown-item:active {
  background-color: #00b602;
}

.Send-Message.input-row.input-select::before {
  right: 10px;
  top: 0;
  bottom: 0;
  padding: 0;
  font-size: 22px;
  color: #d5d5d5;
}

.Signle-Offer-Media {
  text-align: center;
}

.Signle-Offer-Media img {
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}

.profil-link:hover {
  background: #00b602;
  color: #fff;
}

.profil-link {
  font-weight: bold;
  background: #fff;
  border: 1px solid #00b602;
  color: #00b601;
  padding: 3px 40px;
  margin-left: 12px;
  border-radius: 30px;
  display: inline-block;
}

.step-value-password {
  position: relative;
  padding-right: 30px;
}

.step-value-password .toggle-password {
  top: -5px;
  right: 0;
}

.read-more-button {
  color: #282828;
  font-weight: 600;
  cursor: pointer;
}

.video-file {
  margin: 0 auto;
  text-align: center;
  /* border-right: 1px solid #363643; */
}

.youtube-dwn {
  margin: 0 auto;
  text-align: center;
}

.upload-videooz h3 {
  color: #1c1b1b;
  font-size: 13px;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 3px;
}

.upload-videooz span {
  color: #6e6f70;
  font-size: 12px;
}

.youtube-dwn form input[type="text"] {
  color: #C3C3D2;
  font-weight: 400;
  font-size: 12px;
  line-height: 38px;
  background-color: #e2ebef;
  border-radius: 30px;
  width: 100%;
  height: 38px;
  border: none;
  padding: 0 16px;
}

.youtube-dwn form button {
  position: absolute;
  top: 0;
  right: 0;
}

.upload-videooz form label,
.youtube-dwn form button {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 13px;
  line-height: 38px;
  background-color: #00b602;
  border-radius: 30px;
  height: 38px;
  padding: 0 33px;
  cursor: pointer;
  border: 0;
}

.youtube-dwn form {
  position: relative;
}

.upload-videooz form {
  margin-top: 64px;
}

.br-right {
  border-right: 1px solid #cccccc5e;
}

#file-upload {
  display: none;
}

.upload-videooz i {
  color: #36474f;
  font-size: 60px;
}

.edit-btn-video {
  position: absolute;
  z-index: 9;
  right: 15px;
  top: 15px;
  margin: 0;
}

.edit-btn-video:hover {
  background: #36474f;
  color: #fcfcfc;
  border-color: #36474f;
}

.Select svg {
  fill: #00b602;
  height: 16px;
}

/* profile page cvtheque modal styles */
.modal-body input,
.modal-body textarea,
.modal-body select {
  box-shadow: 0px 0px 20px 0px #e7e7e7 !important;
}

.modal-body input:focus,
.modal-body textarea:focus,
.modal-body select:focus {
  box-shadow: 0px 1px 15px -3px #00b601 !important;
}

.modal-body textarea:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

.switch-present {
  border: none;
  padding: 0;
  width: initial;
  height: initial;
  line-height: initial;
}

.switch-present * {
  padding: 0;
}



#shareButton {
  margin-left: auto;
  margin-right: 0;
  color: #fff;
  background-color: #78909C;
  font-size: 5px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 12px;
  /* margin-top: 2px; */
  padding: 0;
  padding-left: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
}

#shareButton:hover {
  box-shadow: 1px 1px 1px 0px #74ed74;
}


#image_share {
  margin-left: 5px;
}

.DadupaModal-Footer .DadupaModal-BTNSubmit {
  /* width: 100%; */
  height: 50px;
  font-size: 14px;
  text-transform: initial;
  border-radius: 15px;
  font-weight: 600;
}

.CreatePost-Body textarea {
  box-shadow: none !important;
}

.CreatePost-Body textarea:focus {
  box-shadow: none !important;
}

/* profile page subscribed at div */
.subscribedAt {
  padding: 1.5em 3em;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.subscribedAt img {
  height: 50px;
}

.subscribedAt h5 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #4fcd67;
}

/* -------------------- */
/* Add Project step 3 */
/* -------------------- */

.ql-container.ql-snow {
  height: 250px;
}

/* -------------------- */
/* Settings - Access tables */
/* -------------------- */

.accessTables-container table thead {
  background: #00b602;
}

.accessTables-container table thead th {
  color: #ffffff;
}

.accessTables-container table tbody tr:nth-child(even) {
  background-color: #00b60215;

}

.active-profile-link {
  background: #00b601 !important;
  color: #ffffff !important;
}

.active-profile-link i {
  color: #ffffff !important;
}

.tooltip-add-btn {
  width: 100%;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  margin-top: 8px;
  font-weight: 600;
}

/* ---------------------------------------- */
/* --------- Create Article Page ---------- */
/* ---------------------------------------- */
.create-article .main-heading {
  font-size: 20px;
  color: #2D363A;
  font-weight: 700;
}

.create-article .input-row {
  padding: 0px;
}

.create-article .title-input {
  border: 1px solid #ccc !important;
  border-radius: 1.5em !important;
}

.create-article .title-input:focus {
  border-color: #00b601 !important;
}

.create-article .ql-toolbar {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
}

.create-article .ql-container {
  background: #ffffff;
  border-bottom-left-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
  min-height: 50vh;
}

.create-article .sidebar {
  background: #ffffff;
  border-radius: 1.5em;
  padding: 1em;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 3%);
  margin-left: 1.5em;
}

.create-article .sidebar .sidebar-title {
  font-size: 16px;
  color: #2D363A;
  font-weight: 700;
}

.create-article .sidebar .edit-btn {
  border: none;
  background: none;
  color: #00b601;
}

.create-article .sidebar .action-btn {
  border-radius: 1em;
  border: 1px solid #00b601;
  background: #00b601;
  color: #ffffff;
  padding: .4em;
}

.article-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  align-items: stretch;
  background-color: #FFF;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, .04);
  border-radius: 15px;
  min-height: 220px;
  position: relative;
}
.article-box #shareButton{
  color: #78909C;
  background-color: transparent;
  width: auto;
  height: auto;
}
.article-box #shareButton i{
  color: #78909C;
  font-size: 18px;
  margin: 0;
}
.article-thumbnail{
  flex: 0 0 225px;
  max-width: 225px;
}
.article-meta{
  padding: 0 20px;
  flex: 0 0 calc(100% - 225px);
  max-width: calc(100% - 225px);
  display: flex;
  align-items: center;
}
.article-meta-content a:hover{
  text-decoration: none;
}
.article-meta-content a .article-title:hover{
  color: #00b601;
  text-decoration: none;
}
.article-thumb {
  width: 225px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 100%;
}

.article-sidebar-item{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.article-thumb-sidebar {
  flex: 0 0 100px;
  max-width: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.article-thumb-sidebar img{
  width: 100%;
  height: 100%;
  margin-right: 1.5em;
  object-fit: cover;
}

.article-meta-sidebar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.article-content-sidebar{
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
  padding-left: 15px;
}
.article-reactions-sidebar{
  font-size: 12px;
  color: #78909C;
}
.article-reactions-sidebar ul{
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0;
}
.article-reactions-sidebar ul li{
  display: flex;
  align-items: center;
  gap: 5px;
}
.article-date {
  font-size: 12px;
  color: #78909C;
  margin: 0;
}

.article-categories {
  font-size: 13px;
}

.article-title {
  font-weight: 600;
  color: #2D363A;
  font-size: 18px;
  margin: 0 0 15px;
  transition: all .20s linear;
  padding-right: 30px;
  line-height: 26px;
}

.article-desc {
  margin: 0;
  font-size: 14px;
  text-align: justify;
}

.articles-list-sidebar .articles-list-title {
  font-weight: 700;
  color: #2D363A;
  font-size: 22px;
  margin-bottom: 30px;
}

.article-sidebar-title {
  color: #2D363A;
  font-size: 16px;
  margin: 0;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.article-footer{
  margin-top: 20px;
}

.article-footer ul{
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0;
  padding: 0;
}

.article-footer ul li{
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #78909C;
}
.article-footer ul li span{
  font-weight: 500;
}
.article-footer ul li i{
  font-size: 14px;
}
.article-filter-btn {
  background: #00b601;
  color: #fff;
  border: none;
  border-radius: 1em;
  padding: 0 0.8em;
  height: 100%;
}

.article-list-author {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 5px;
  margin-top: 12px;
}

.article-list-author img {
  width: 26px;
  height: 26px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 5px;
}

.article-list-author h4 {
  font-size: 12px;
  margin: 0;
  color: #78909C;
}
.filter-mobile{
  display: none;
}
.Dadupa-Notifs-Box{
  display: none;
}
.Dadupa-Notifs-Box.Notifs-Box-Active{
  display: block;
}

@media(min-width: 768px) and (max-width: 992px){
  .article-title{
    padding: 0;
  }
}
@media(max-width: 768px){
  .Post-Actions{

  }
  .dadupa-navigation{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, .05);
    z-index: 9;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .dadupa-navigation .center-nav{
    height: auto;
  }
  .Dadupa-Nav{
    display: flex;
    width: 100%;
  }
  .Dadupa-Nav .Nav-Item{
    flex: 1 0 0;
  }
  .Dadupa-Nav .Nav-Link{
    text-align: center;
    font-size: 12px;
    line-height: 1;
  }
  .Dadupa-Nav .Nav-Link span{
     display: block;
     text-align: center;
     margin-top: 5px;
  }
  .Dadupa-Nav .Nav-Link .uil{
    font-size: 24px;
  }
  .Dadupa-Mini-Profile{
    display: block;
    transform: scale(1) translateX(0);
    width: 100%;
    height: calc(100vh - 50px);
    position: fixed;
    bottom: 0;
  }
  .Dadupa-Mini-Profile.Mini-Profile-Active{
    visibility: visible;
  }
  .filter-mobile{
    display: flex;
    justify-content: space-between;
    padding: 30px 0 10px;
    align-items: center;
  }
  .Filter-Row{
    display: none;
  }
  .filter-mobile h4{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .filter-mobile button{
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
  }
  .filter-mobile button .uil{
    font-size: 20px;
  }

  /*  */
  .Single-Wrapper{
    padding-top: 25px;
  }
  .Single-Wrapper .Profile-Navigation{
    margin-top: 0;
  }

  .media-wrap > div{
    height: 260px !important;
  }

  .wizard-fieldset .form-inputs{
    margin: 0;
    margin-bottom: 35px;
  }

  .offer-wizard-wrapper .page-header p{
    line-height: 18px;
  }

  .article-box{
    display: block;
  }
  .article-thumbnail{
    max-width: 100%;
  }
  .article-thumb{
    width: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
  }
  .article-meta{
    max-width: 100%;
    padding: 20px;
  }
  .article-title{
    line-height: 24px;
  }
}

@media(min-width: 768px) and (max-width: 1024px){
  .articles-list-sidebar .articles-list-title{
    font-size: 18px;
  }
  .article-meta,
  .article-thumbnail{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .article-thumbnail{
    margin-bottom: 0;
  }
  .article-thumb{
    width: 100%;
    max-height: 300px;
    border-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .article-meta{
    padding: 20px;
  }
  .article-content-sidebar,
  .article-thumb-sidebar{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .article-thumb-sidebar{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 140px;
  }
  .article-content-sidebar{
    padding: 15px 0 0;
  }
  .article-thumb-sidebar img{
    margin: 0;
    height: 100%;
  }
  .article-sidebar-title{
    font-size: 14px;
  }
  .icon-clap::before{
    width: 16px;
    height: 16px;
  }
  .article-list-author{
    margin-top: 15px;
  }
}

.empty-state{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 260px);
}
.empty-state .single-offer-name{
  text-align: center;
  margin-bottom: 30px;
}
.empty-state-content img{
  width: auto;
  height: 240px;
}
.empty-state .action-button{
  width: auto;
  display: flex;
  margin: 30px auto 0;
  padding: 0 20px;
  align-items: center;
  gap: 10px;
}
.empty-state .action-button i{
  font-size: 24px;
}

@media(min-width: 768px) and (max-width: 992px){
  .order-middle{
    order: 2;
  }
  .dadupa-order-last{
    order: 12;
  }
  .order-first{
    order: 1;
  }
  .Right-Side{
    margin-top: 20px;
  }
}


/* DADUPA TABS */
.dadupa-tabs.nav-tabs{
  margin-bottom: 30px;
  border-color: #f4f4f4;
  display: flex;
  gap: 30px;
}
.dadupa-tabs.nav-tabs .nav-link{
  padding: 15px 0;
  border: none;
  background-color: transparent;
}
.dadupa-tabs.nav-tabs .nav-link{
  position: relative;
  font-weight: 500;
  color: #2D363A;
}
.dadupa-tabs.nav-tabs .nav-link.active{
  color: #00b601;
}
.dadupa-tabs.nav-tabs .nav-link.active::before{
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #00b601;
  height: 2px;
  width: 100%;
}